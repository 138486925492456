import { FC, useEffect, useMemo } from "react";

import { Typography } from "@mui/material";

import { SearchTypes } from "../../../../types/panels/searchPanel/search";

import { RECORD_TYPES } from "../../../../constants/panels/searchPanel/queryBuilder/attributeValues";
import { SEARCH_TYPES } from "../../../../constants/panels/searchPanel/search";

import useMapDrawStore from "../../../../store/map/draw/mapDrawStore";
import useQueryBuilderStore from "../../../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../../../store/search/uwiSearch/searchUWIStore";

import useUnitOfMeasure from "../../../../customHooks/common/useUnitOfMeasure";
import useWellCountData from "../../../../customHooks/useWellCountData";

import { createRecordTypeBounds } from "../../../../utils/common/boundsData";
import { getNumberWithComma } from "../../../../utils/helper";

interface ExpectedCountProps {
  isDefaultZero: boolean;
  searchType?: SearchTypes;
}

const ExpectedCount: FC<ExpectedCountProps> = ({
  searchType = SEARCH_TYPES.ATTRIBUTE_SEARCH,
  isDefaultZero = true,
}) => {
  const { isMetricOnSelection } = useUnitOfMeasure();

  const {
    data: wellCountData,
    isLoading,
    setData: setWellCountData,
    getExpectedWellCount,
  } = useWellCountData();

  const currentBounds = useQueryBuilderStore((state) => state.currentBounds);
  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);
  const uwisToSearch = useUWISearchStore((state) => state.uwisToSearch);
  const uploadedUwiFileId = useUWISearchStore(
    (state) => state.uploadedUwiFileId
  );

  const displayName = useMemo(() => {
    if (isDefaultZero || isLoading) return 0;
    return getNumberWithComma(wellCountData);
  }, [isLoading, isDefaultZero, wellCountData]);

  useEffect(() => {
    if (searchType !== SEARCH_TYPES.ATTRIBUTE_SEARCH) {
      return;
    }

    setWellCountData(0);
    if (!drawnPolygons?.length && !currentBounds.length && !shapeId) {
      return;
    }

    getExpectedWellCount({
      drawnPolygons,
      bounds: currentBounds,
      shapeId,
      fileId: "",
      searchedUWIs: [],
      fetchedSavedSearchTrigger: false,
      isMetric: isMetricOnSelection,
      searchType,
    });
  }, [currentBounds, drawnPolygons, shapeId, isMetricOnSelection]);

  useEffect(() => {
    if (
      searchType !== SEARCH_TYPES.UWI_SEARCH &&
      searchType !== SEARCH_TYPES.UWI_FILE_SEARCH &&
      searchType !== SEARCH_TYPES.UWI_CONVERTED_FILE_SEARCH
    ) {
      return;
    }

    setWellCountData(0);
    if (!uwisToSearch?.length && !uploadedUwiFileId) {
      return;
    }

    getExpectedWellCount({
      bounds: [createRecordTypeBounds(RECORD_TYPES.WELLS_AND_PERMIT)],
      searchedUWIs: uwisToSearch,
      fileId: uploadedUwiFileId ?? "",
      drawnPolygons: [],
      shapeId: "",
      fetchedSavedSearchTrigger: false,
      isMetric: isMetricOnSelection,
      searchType,
    });
  }, [uwisToSearch, uploadedUwiFileId, isMetricOnSelection]);

  return (
    <div className="count-container">
      <Typography>EXPECTED RECORDS COUNT</Typography>
      <Typography className="well-count-value">{displayName}</Typography>
    </div>
  );
};

export default ExpectedCount;

import { useState } from "react";

import { SearchRequestPayload } from "../../types/common/api";
import {
  BaseSearchCriteria,
  SearchWellDataPayload,
  SortBySelected,
} from "../../types/common/search";
import { WellData } from "../../types/common/wells";
import { GetGridDataResponse } from "../../types/grid/useGridData";

import config from "../../configs/appSettings";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { defaultSortPayload } from "../../utils/datagrid";

import { callServiceAPI } from "../../action/callServiceAPI";
import useSearchRequest from "../common/useSearchRequest";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useUwiFileUpload from "../search/useUwiFileUpload";
import { useGridColumn } from "./useGridColumn";
import { useGridSelectedData } from "./useGridSelectedData";

const searchURL = `${config.endpoints.wellService}api/wells/search/`;

export const useGridData = () => {
  const configPageLimit = config.searchPageLimit;
  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const { getRequiredColumns, setGridRowData } = useGridColumn();
  const { buildSearchRequestByParam } = useSearchRequest();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const sortByAnalysis = useDataGridStore((state) => state.sortByAnalysis);

  const updateGridFilteredLoading = useDataGridStore(
    (state) => state.updateGridFilteredLoading
  );
  const updateGridTotalCount = useDataGridStore(
    (state) => state.updateGridTotalCount
  );
  const updateGridFilteredCount = useDataGridStore(
    (state) => state.updateGridFilteredCount
  );
  const updateBatchWellGridData = useDataGridStore(
    (state) => state.updateBatchWellGridData
  );
  const updateBatchWellGridDataSuccess = useDataGridStore(
    (state) => state.updateBatchWellGridDataSuccess
  );
  const updateBatchWellGridDataLoading = useDataGridStore(
    (state) => state.updateBatchWellGridDataLoading
  );
  const updateColumnsFetched = useDataGridStore(
    (state) => state.updateColumnsFetched
  );

  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );

  const { isDeselectedIds } = useGridSelectedData();

  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );

  const selectedPermitIdsKeys = useMapSelectionStore(
    (state) => state.selectedPermitIdsKeys
  );

  const deselectedWellIdsKeys = useMapSelectionStore(
    (state) => state.deselectedWellIdsKeys
  );
  const deselectedPermitIdsKeys = useMapSelectionStore(
    (state) => state.deselectedPermitIdsKeys
  );

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<WellData[] | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [success, setSuccess] = useState(false);

  const searchGridData = async ({
    drawnPolygons = [],
    currentBounds = [],
    searchedUWIs = [],
    shapeId = "",
    fileId = "",
    offset = 0,
    pageLimit = configPageLimit,
    sort = [],
    filters = [],
    columns = [],
    optimizeColumns = false,
  }: SearchWellDataPayload) => {
    setIsLoading(true);
    setData(null);
    setError(null);
    setSuccess(false);
    updateBatchWellGridDataSuccess(false);
    updateBatchWellGridDataLoading(true);

    const hasFilters = filters?.length;
    const hasOffset = offset === 0;

    if (hasOffset) {
      if (hasFilters) {
        updateGridFilteredLoading(true);
      } else {
        updateBatchWellGridDataLoading(true);
      }
    }

    const requestBodySearchCriteria: BaseSearchCriteria = {
      drawnPolygons,
      shapeId,
      currentBounds,
      fileId,
      searchedUWIs,
      filters,
    };

    const searchRequestBody = buildSearchRequestByParam(
      requestBodySearchCriteria
    );

    const body: SearchRequestPayload = {
      ...searchRequestBody,
      offset: offset,
      pageLimit: pageLimit,
      ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
    };

    if (sort.length) {
      body.sort = sort;
    }

    const finalColumnsPayload = getRequiredColumns(columns, optimizeColumns);
    body.columns = finalColumnsPayload;

    if (sortByAnalysis) {
      const sortBySelectedRequest: SortBySelected = {
        prioritizeSelected: !isDeselectedIds,
        wellIds: isDeselectedIds ? deselectedWellIdsKeys : selectedWellIdsKeys,
        permitIds: isDeselectedIds
          ? deselectedPermitIdsKeys
          : selectedPermitIdsKeys,
      };
      body.sortBySelected = sortBySelectedRequest;

      if (!sort.length) {
        body.sort = defaultSortPayload;
      }
    }

    try {
      const initialResponse = await callServiceAPI<GetGridDataResponse>(
        searchURL,
        body,
        getUWIFileIdWithRetry,
        uploadedUWIFile,
        isFromUploadedUWIFile
      );

      if (!initialResponse || !("data" in initialResponse)) return;

      const newGridData = setGridRowData(initialResponse.data.wells, columns);

      updateBatchWellGridData(newGridData);
      setData(initialResponse.data.wells);
      setIsLoading(false);
      setSuccess(true);
      updateBatchWellGridDataSuccess(true);
      updateBatchWellGridDataLoading(false);

      if (columns.length) updateColumnsFetched(columns);

      if (hasOffset && hasFilters) {
        updateGridFilteredLoading(false);
      }
    } catch (err) {
      console.debug("getWellsData error", err);
      setError(err);
      setIsLoading(false);
      updateBatchWellGridDataLoading(false);
      if (hasOffset) {
        if (hasFilters) {
          updateGridFilteredCount(0);
          updateGridFilteredLoading(false);
        } else {
          updateGridTotalCount(0);
          updateGridFilteredCount(0);
          updateBatchWellGridDataLoading(false);
        }
      }
    }
  };

  return {
    isLoading,
    data,
    error,
    success,
    searchGridData,
  };
};

import { useMemo } from "react";

import { WellData } from "../../../types/common/wells";
import { PermitPanelInfo } from "../../../types/panels/permitPanel/permitPanel";
import { WellPermitPanelState } from "../../../types/panels/sidebar";

import { SIDEBAR_MENU_TEXTS } from "../../../constants/panels/menu";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useMapHoverStore from "../../../store/map/hover/mapHoverStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import { getIdByHeaderKey } from "../../../utils/map/gis/common";

const usePermitPanelViewAction = () => {
  const mapHoverData = useMapHoverStore((state) => state.mapHoverData);

  const wellInfoList = usePanelsStore((state) => state.wellList);
  const permitsInfoList = usePanelsStore((state) => state.permitsList);
  const updatePermitsList = usePanelsStore((state) => state.updatePermitsList);
  const toggleAlertMessage = usePanelsStore(
    (state) => state.toggleAlertMessage
  );
  const toggleIsWellPanelNavCollapsed = usePanelsStore(
    (state) => state.toggleIsWellPanelNavCollapsed
  );
  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const updateActiveWellPanel = usePanelsStore(
    (state) => state.updateActiveWellPanel
  );
  const updateActivePermitPanel = usePanelsStore(
    (state) => state.updateActivePermitPanel
  );
  const selectedCardPermitIDs = usePanelsStore(
    (state) => state.selectedCardPermitIDs
  );
  const addSelectedCardPermitID = usePanelsStore(
    (state) => state.addSelectedCardPermitID
  );

  const isPermitSystemExisting = useMemo(() => {
    if (permitsInfoList.length && mapHoverData) {
      return permitsInfoList.find(
        (permit) =>
          permit.wellHeaderPermitKey === mapHoverData.info.WellHeaderPermitKey
      );
    }

    return undefined;
  }, [permitsInfoList, mapHoverData]);

  const isPermitSystemActivelyViewing = useMemo(() => {
    if (isPermitSystemExisting && isPermitSystemExisting.isActive) return true;
    return false;
  }, [isPermitSystemExisting, isPermitSystemExisting?.isActive]);

  const shouldDisablePermitCardButton = useMemo(
    () => Boolean(isPermitSystemActivelyViewing),
    [isPermitSystemActivelyViewing]
  );

  const addNewPermitPanel = (permitData: WellData) => {
    const wellsAndPermitPanel: WellPermitPanelState[] = [
      ...wellInfoList,
      ...permitsInfoList,
    ];

    if (wellsAndPermitPanel.length === 10) {
      toggleAlertMessage(true);
      return;
    }

    const { UWI, WellHeaderPermitKey, WellNumber, Country } = permitData;

    const permitPanelInfo: PermitPanelInfo = {
      isActive: true,
      recordType: RECORD_TYPES.PERMIT,
      createdData: new Date(),
      wellHeaderPermitKey: WellHeaderPermitKey,
      activePage: 1,
      uwi: UWI ?? "",
      wellNumber: WellNumber ?? "",
      permitID: getIdByHeaderKey(WellHeaderPermitKey),
      country: Country,
    };

    updatePermitsList(permitPanelInfo);
    updatePermitSpotSize(permitPanelInfo);
  };

  const openPermitPanelDrawer = () => {
    toggleIsWellPanelNavCollapsed(true);
    toggleLeftSidePanel(true);
    updateActiveWellPanel(false);
    updateActivePanel(SIDEBAR_MENU_TEXTS.WELL_PANEL);
  };

  const handleOpenPermitPanel = () => {
    if (mapHoverData?.info.PermitID && mapHoverData.info.WellHeaderPermitKey) {
      if (!isPermitSystemExisting) {
        addNewPermitPanel(mapHoverData.info);
      } else {
        updateActivePermitPanel(true, isPermitSystemExisting);
      }
      openPermitPanelDrawer();
    }
  };

  const viewNewActivePermitPanel = (gridDataHeaderKey: string) => {
    const newActivePermitPanel = permitsInfoList.find(
      (permitInfo) => permitInfo.wellHeaderPermitKey === gridDataHeaderKey
    );
    if (!newActivePermitPanel) return;

    updateActivePermitPanel(true, newActivePermitPanel);
  };

  const handleOpenPermitPanelViaGrid = (
    isExisting: boolean,
    permitData: WellData
  ) => {
    const { WellHeaderPermitKey } = permitData;
    if (isExisting) {
      viewNewActivePermitPanel(WellHeaderPermitKey);
    } else {
      addNewPermitPanel(permitData);
    }
    openPermitPanelDrawer();
  };

  const updatePermitSpotSize = (permitInfo: PermitPanelInfo) => {
    if (selectedCardPermitIDs.includes(permitInfo.permitID)) return;

    addSelectedCardPermitID(permitInfo.permitID);
  };

  return {
    shouldDisablePermitCardButton,
    handleOpenPermitPanel,
    handleOpenPermitPanelViaGrid,
  };
};

export default usePermitPanelViewAction;

import { FC, SVGProps } from "react";

interface ExportPackageManagerIconProp extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const ExportPackageManagerIcon: FC<ExportPackageManagerIconProp> = ({
  pathFill = "white",
}) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 17C4.0875 17 3.73437 16.8531 3.44062 16.5594C3.14687 16.2656 3 15.9125 3 15.5V4.5C3 4.0875 3.14687 3.73438 3.44062 3.44063C3.73437 3.14688 4.0875 3 4.5 3H10V4.5H4.5V15.5H15.5V10H17V15.5C17 15.9125 16.8531 16.2656 16.5594 16.5594C16.2656 16.8531 15.9125 17 15.5 17H4.5ZM8.0625 13L7 11.9375L14.4375 4.5H12V3H17V8H15.5V5.5625L8.0625 13Z"
        fill={pathFill}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default ExportPackageManagerIcon;

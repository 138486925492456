import { FC, useEffect } from "react";

import { Menu, MenuItem, MenuProps } from "@mui/material";

import classNames from "classnames";

import { SavedSearchInfo } from "../../../types/panels/savedSearchPanel/savedSearchData";
import { SearchTypes } from "../../../types/panels/searchPanel/search";
import { MenuPlacements } from "../../../types/shareLink/shareLink";

import { SAVED_SEARCH_VISIBILITY_PUBLIC } from "../../../constants/panels/savedSearchPanel/hooks";
import {
  SHARE_SEARCH_BOTTOM_MENU_PLACEMENT,
  SHARE_SEARCH_RIGHT_MENU_PLACEMENT,
} from "../../../constants/panels/shareLink";

import useAlertPopupStore from "../../../store/map/alert/alertStore";

import useSavedSearchData from "../../../customHooks/savedSearch/createUpdateSavedSearch/useCreateUpdateSavedSearch";

import { formatCurrentDateTime } from "../../../utils";
import CopyIcon from "../../common/icons/CopyIcon";

interface ShareSearchMenuProps {
  open: MenuProps["open"];
  anchorEl: MenuProps["anchorEl"];
  handleClose?: () => void;
  searchType: SearchTypes;
  isFilterIncluded: boolean;
  shareInfo?: SavedSearchInfo;
  placement?:
    | typeof SHARE_SEARCH_BOTTOM_MENU_PLACEMENT
    | typeof SHARE_SEARCH_RIGHT_MENU_PLACEMENT;
}

const ShareSearchMenu: FC<ShareSearchMenuProps> = ({
  open,
  anchorEl,
  handleClose,
  searchType,
  isFilterIncluded,
  shareInfo,
  placement = SHARE_SEARCH_BOTTOM_MENU_PLACEMENT,
}) => {
  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const {
    isLoading: savedSearchDataLoading,
    data: savedSearchData,
    error: savedSearchError,
    createSavedSearch,
    createDuplicateSavedSearch,
  } = useSavedSearchData();

  const bottomPlacement: MenuPlacements = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  const rightPlacement: MenuPlacements = {
    anchorOrigin: {
      vertical: "center",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "left",
    },
  };

  const handleCopyLinkClick = async () => {
    const saveName = `Shared Search ${formatCurrentDateTime()}`;

    if (shareInfo?.id) {
      createDuplicateSavedSearch({
        ...shareInfo,
        searchName: saveName,
        visibility: SAVED_SEARCH_VISIBILITY_PUBLIC,
      });
    } else {
      createSavedSearch(
        saveName,
        searchType,
        isFilterIncluded,
        false,
        SAVED_SEARCH_VISIBILITY_PUBLIC
      );
    }
  };

  useEffect(() => {
    // note: make sure current document is focused else will return Document Exception Error
    // not focused ex: alert box is opened or cursor is focused on dev tools or outside browser
    if (
      !savedSearchDataLoading &&
      savedSearchData?.id &&
      window.document.hasFocus()
    ) {
      const savedSearchLink = `${window.location.origin}/${savedSearchData.id}`;
      navigator.clipboard.writeText(savedSearchLink);

      updateAlertState({
        message: "Search Link copied to your clipboard.",
        severity: "success",
      });
    } else if (
      !savedSearchDataLoading &&
      savedSearchData?.id &&
      !window.document.hasFocus()
    ) {
      updateAlertState({
        message: "Cannot copy to clipboard, document is not focused.",
        severity: "error",
      });
    } else if (!savedSearchDataLoading && savedSearchError) {
      updateAlertState({
        message: "Something Went Wrong.",
        severity: "error",
      });
    }
  }, [savedSearchDataLoading, savedSearchData]);

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={
        placement === SHARE_SEARCH_BOTTOM_MENU_PLACEMENT
          ? bottomPlacement.anchorOrigin
          : rightPlacement.anchorOrigin
      }
      transformOrigin={
        placement === SHARE_SEARCH_BOTTOM_MENU_PLACEMENT
          ? bottomPlacement.transformOrigin
          : rightPlacement.transformOrigin
      }
      onClose={handleClose}
      className={classNames("share-search-menu-container", {
        "right-placement": Boolean(
          placement === SHARE_SEARCH_RIGHT_MENU_PLACEMENT
        ),
      })}
    >
      <MenuItem onClick={handleCopyLinkClick} disabled={savedSearchDataLoading}>
        Copy Link{" "}
        <span>
          <CopyIcon />
        </span>
      </MenuItem>
    </Menu>
  );
};

export default ShareSearchMenu;

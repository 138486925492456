import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverVirtualElement,
} from "@mui/material";

import classNames from "classnames";

import { CustomMenuSelectionData } from "../../types/common/customMenuSelection";

interface CustomMenuSelectionProps {
  options: CustomMenuSelectionData[];
  selectionIndicator: React.ReactNode;
  selectedItem: string;
  open: boolean;
  anchorEl:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  onItemClick: (selected: string) => void;
  onClose: () => void;
}

const CustomMenuSelection: React.FC<CustomMenuSelectionProps> = ({
  options,
  selectionIndicator,
  selectedItem,
  open,
  anchorEl,
  onClose,
  onItemClick,
}) => {
  return (
    <Menu
      className="custom-menu-selection"
      anchorEl={anchorEl}
      open={open}
      onClose={() => onClose()}
      slotProps={{
        paper: {
          style: {
            width: 130,
            backgroundColor: "rgba(18, 18, 18, 1)",
          },
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.key}
          onClick={(e) => onItemClick(option.key)}
          className={classNames("custom-menu-selection-item", {
            selected: selectedItem === option.key,
          })}
        >
          <ListItemText className="custom-menu-selection-option-label">
            {option.label}
          </ListItemText>
          {selectedItem === option.key && (
            <ListItemIcon className="custom-menu-selection-icon">
              {selectionIndicator}
            </ListItemIcon>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default CustomMenuSelection;

import { LayerNameMapper } from "../types/map/layers/common";

export const MONTHS_12x24 = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "Avg",
];

export const HOURS_12x24 = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "Avg",
];

export const PRODUCTION_PLOT = "Production Plot";
export const TYPE_CURVE = "Type Curve";
export const BAR_CHART = "Bar Chart";
export const HORIZONTAL_BAR_CHART = "Horizontal Bar Chart";
export const SCATTER_PLOT = "Scatter Plot";
export const FULL_WIDTH_STACKED_BAR_CHART = "Full Width Stacked Bar Chart";
export const STACKED_AREA = "Stacked Area";
export const GUN_BARREL = "Gun Barrel";

export const LOCK_ORIENTATION = "Lock Orientation";
export const UNLOCK_ORIENTATION = "Unlock Orientation";

export const CHARTS = [PRODUCTION_PLOT, TYPE_CURVE];

export const CHARTS_FIELDS_MAPPER = {
  [PRODUCTION_PLOT]: "production",
  [TYPE_CURVE]: "production",
};

export const COUNTRY = {
  US: "US",
  CA: "CA",
} as const;

export const GEOPOINT = "GeoPoint";

export const DYNAMIC_VECTOR_TILES = "DVT";
export const WMTS_FETCH_TYPE = "WMTS";
export const WMS_FETCH_TYPE = "WMS";
export const WFS_FETCH_TYPE = "WFS";

//Geometry Types
export const GEOMETRY_MULTILINESTRINGS_TYPE = "MultiLineString";
export const GEOMETRY_LINESTRING_TYPE = "LineString";

// NOTE: DO NOT CHANGE THIS WHEN UPDATING LABELS ON MAP SETTINGS
// THESE LAYER NAMES ARE SAVED. SO UPDATING THIS MAY PRODUCE ISSUE WHEN LOADING.
// INSTEAD, UPDATE THE "LAYER_NAME_TO_LABEL_MAPPER"
export const WELL_SPOTS = "Well Spots";
export const DYNAMIC_WELL_SPOTS = "Dynamic Well Spots";
export const SURFACE_WELL_SPOTS = "Surface Well Spots";
export const BOTTOM_WELL_SPOTS = "Bottom Well Spots";
export const DYNAMIC_BOTTOM_WELL_SPOTS = "Dynamic Bottom Well Spots";
export const PERMIT_SPOTS = "Permit Spots";
export const DYNAMIC_PERMIT_SPOTS = "Dynamic Permit Spots";
export const TRENDS_AND_BASINS = "Trends & Basins";
export const MAGNETIC_ALY = "Magnetic Anomaly";
export const SHADED_ALY = "Shaded Anomaly";
export const ISOSTATIC_ALY = "Isostatic Anomaly";
export const BOUGUER_ALY = "Bouguer Anomaly";
export const CANADA_LAND_GRIDS = "Canada Land Grids";
export const LAND_GRIDS = "Land Grids";
export const MAP_SEARCH_PIPELINES = "Map Search Pipelines";
export const OFFSHORE_BLOCKS = "OffShore Blocks";
export const BUBBLE_MAP = "Bubble Map";
export const MAP_SCALE_BAR = "Map Scale Bar";
export const WELL_PATH_AND_STICKS = "Well Paths and Sticks";
export const WELL_PATHS = "Well Paths";
export const WELL_STICKS = "Well Sticks";
export const TRENDS_OUTLINE = "TGS Trends Outline";
export const MAJOR_BASINS = "TGS Major Basins";
export const TRENDS = "TGS Trends";
export const TGS_SURVEYS = "TGS Seismic Surveys";
export const STRATIGRAPHIC_MODELS_AREA = "TGS Stratigraphic Model Outlines";
export const GPB_01_RUSTLER = "GPB_01_RUSTLER";
export const GPB_02_SALADO = "GPB_02_SALADO";
export const GPB_03_BELL_CANYON_TANSIL = "GPB_03_BELL_CANYON_TANSIL";
export const GPB_03A_CHERRY_CANYON_QUEEN = "GPB_03A_CHERRY_CANYON_QUEEN";
export const GPB_04_BRUSHY_CANYON_SAN_ANDRES =
  "GPB_04_BRUSHY_CANYON_SAN_ANDRES";
export const GPB_04A_GLORIETA = "GPB_04A_GLORIETA";
export const GPB_05_TOP_BS_CLEARFORK = "GPB_05_TOP_BS_CLEARFORK";
export const GPB_06_1ST_BS_SAND_WICHITA_SPRABERRY =
  "GPB_06_1ST_BS_SAND_WICHITA_SPRABERRY";
export const GPB_06A1_MIDDLE_SPRABERRY = "GPB_06A1_MIDDLE_SPRABERRY";
export const GPB_06A2_2ND_BONE_SPRING_LIME = "GPB_06A2_2ND_BONE_SPRING_LIME";
export const GPB_06B1_LOWER_SPRABERRY = "GPB_06B1_LOWER_SPRABERRY"; //
export const GPB_06B2_2ND_BONE_SPRING_SAND = "GPB_06B2_2ND_BONE_SPRING_SAND";
export const GPB_06C0_LOWER_SPRABERRY_SHALE = "GPB_06C0_LOWER_SPRABERRY_SHALE";
export const GPB_06C1_3RD_BONE_SPRING_LIME = "GPB_06C1_3RD_BONE_SPRING_LIME";
export const GPB_06C2_3RD_BONE_SPRING_LIME_SANDSTONE_TOP =
  "GPB_06C2_3RD_BONE_SPRING_LIME_SANDSTONE_TOP";
export const GPB_06C3_3RD_BONE_SPRING_LIME_SANDSTONE_BASE =
  "GPB_06C3_3RD_BONE_SPRING_LIME_SANDSTONE_BASE";
export const GPB_06D_3RD_BONE_SPRING_SAND = "GPB_06D_3RD_BONE_SPRING_SAND";
export const GPB_06D_DEAN_SAND = "GPB_06D_DEAN_SAND";
export const GPB_07_TOP_WOLFCAMP = "GPB_07_TOP_WOLFCAMP";
export const GPB_07A1_WOLFCAMP_X = "GPB_07A1_WOLFCAMP_X";
export const GPB_07A2_WOLFCAMP_Y = "GPB_07A2_WOLFCAMP_Y";
export const GPB_07A3_WOLFCAMP_SHALE = "GPB_07A3_WOLFCAMP_SHALE";
export const GPB_07B1_MID_WOLFCAMP_B = "GPB_07B1_MID_WOLFCAMP_B";
export const GPB_07B2_DEL_WOLFCAMP_B = "GPB_07B2_DEL_WOLFCAMP_B";
export const GPB_07C_INTRA_WOLFCAMP_C = "GPB_07C_INTRA_WOLFCAMP_C";
export const GPB_08_CISCO_WOLFCAMP_D = "GPB_08_CISCO_WOLFCAMP_D";
export const GPB_09_STRAWN = "GPB_09_STRAWN";
export const GPB_10_BARNETT = "GPB_10_BARNETT";
export const GPB_11_MISSISSIPPIAN_LIMESTONE = "GPB_11_MISSISSIPPIAN_LIMESTONE";
export const GPB_12_WOODFORD_SHALE = "GPB_12_WOODFORD_SHALE";
export const GPB_13_DEVONIAN_CARBONATE = "GPB_13_DEVONIAN_CARBONATE";
export const GPB_14_SILURIAN_SHALE = "GPB_14_SILURIAN_SHALE";
export const GPB_15_FUSSLEMAN = "GPB_15_FUSSLEMAN";
export const GPB_16_SIMPSON = "GPB_16_SIMPSON";
export const GPB_17_ELLENBURGER = "GPB_17_ELLENBURGER";
export const GPB_18_BASEMENT = "GPB_18_BASEMENT";
export const MID_15A_MONTOYA = "MID_15A_MONTOYA";
export const FIRST_ST_BONE_SPRINGS_ISOPACH = "FIRST_ST_BONE_SPRINGS_ISOPACH";
export const SECOND_BONE_SPRINGS_ISOPACH = "SECOND_BONE_SPRINGS_ISOPACH";
export const THIRD_BONE_SPRINGS_ISOPACH = "THIRD_BONE_SPRINGS_ISOPACH";
export const WOLFCAMPX_ISOPACH = "WOLFCAMPX_ISOPACH";
export const UPPERSPRABERRY_ISOPACH = "UPPERSPRABERRY_ISOPACH";
export const MIDDLESPRABERRY_ISOPACH = "MIDDLESPRABERRY_ISOPACH";
export const LOWERSPRABERRY_ISOPACH = "LOWERSPRABERRY_ISOPACH";
export const SANANDRES_ISOPACH = "SANANDRES_ISOPACH";
export const WOLFCAMPY_ISOPACH = "WOLFCAMPY_ISOPACH";
export const EAGLE_FORD_02_VICKSBURG_GROUP_GRID =
  "EAGLE_FORD_02_VICKSBURG_GROUP_GRID";
export const EAGLE_FORD_03_CLAIBORNE_GROUP_GRID =
  "EAGLE_FORD_03_CLAIBORNE_GROUP_GRID";
export const EAGLE_FORD_03B_LOWER_CLAIBORNE_GRID =
  "EAGLE_FORD_03B_LOWER_CLAIBORNE_GRID";
export const EAGLE_FORD_04_UPPER_WILCOX_GRID =
  "EAGLE_FORD_04_UPPER_WILCOX_GRID";
export const EAGLE_FORD_04A_MIDDLE_WILCOX_GRID =
  "EAGLE_FORD_04A_MIDDLE_WILCOX_GRID";
export const EAGLE_FORD_04B_LOWER_WILCOX_GRID =
  "EAGLE_FORD_04B_LOWER_WILCOX_GRID";
export const EAGLE_FORD_05_MIDWAY_GROUP_GRID =
  "EAGLE_FORD_05_MIDWAY_GROUP_GRID";
export const EAGLE_FORD_06_NAVARRO_GROUP_GRID =
  "EAGLE_FORD_06_NAVARRO_GROUP_GRID";
export const EAGLE_FORD_07_TAYLOR_GROUP_GRID =
  "EAGLE_FORD_07_TAYLOR_GROUP_GRID";
export const EAGLE_FORD_07A_ANACACHO_GRID = "EAGLE_FORD_07A_ANACACHO_GRID";
export const EAGLE_FORD_08_AUSTIN_CHALK_GRID =
  "EAGLE_FORD_08_AUSTIN_CHALK_GRID";
export const EAGLE_FORD_09_UPPER_EAGLE_FORD_GRID =
  "EAGLE_FORD_09_UPPER_EAGLE_FORD_GRID";
export const EAGLE_FORD_10_LOWER_EAGLE_FORD_GRID =
  "EAGLE_FORD_10_LOWER_EAGLE_FORD_GRID";
export const EAGLE_FORD_10A_WOODBINE_GRID = "EAGLE_FORD_10A_WOODBINE_GRID";
export const EAGLE_FORD_10B_MANESS_GRID = "EAGLE_FORD_10B_MANESS_GRID";
export const EAGLE_FORD_11_BUDA_GRID = "EAGLE_FORD_11_BUDA_GRID";
export const EAGLE_FORD_11A_DEL_RIO_GRID = "EAGLE_FORD_11A_DEL_RIO_GRID";
export const EAGLE_FORD_11B_GEORGETOWN_GRID = "EAGLE_FORD_11B_GEORGETOWN_GRID";
export const EAGLE_FORD_11C_EDWARDS_GRID = "EAGLE_FORD_11C_EDWARDS_GRID";
export const EAGLE_FORD_12_GLEN_ROSE_GRID = "EAGLE_FORD_12_GLEN_ROSE_GRID";
export const EAGLE_FORD_13_PEARSAL_GRID = "EAGLE_FORD_13_PEARSAL_GRID";
export const EAGLE_FORD_14_SLIGO_PETTET_GRID =
  "EAGLE_FORD_14_SLIGO_PETTET_GRID";
export const ANADARKO_01_STONE_CORRAL_STRUCTURE =
  "ANADARKO_01_STONE_CORRAL_STRUCTURE";
export const ANADARKO_02_TOP_HUTCHINSON_STRUCTURE =
  "ANADARKO_02_TOP_HUTCHINSON_STRUCTURE";
export const ANADARKO_03_BASE_HUTCHINSON_STRUCTURE =
  "ANADARKO_03_BASE_HUTCHINSON_STRUCTURE";
export const ANADARKO_04_CHESTER_STRUCTURE = "ANADARKO_04_CHESTER_STRUCTURE";
export const ANADARKO_05_WABAUNSEE_STRUCTURE =
  "ANADARKO_05_WABAUNSEE_STRUCTURE";
export const ANADARKO_06_HEEBNER_SHALE_STRUCTURE =
  "ANADARKO_06_HEEBNER_SHALE_STRUCTURE";
export const ANADARKO_07_LANSING_STRUCTURE = "ANADARKO_07_LANSING_STRUCTURE";
export const ANADARKO_08_HOXBAR_KANSAS_CITY_STRUCTURE =
  "ANADARKO_08_HOXBAR_KANSAS_CITY_STRUCTURE";
export const ANADARKO_09_MARMATON_STRUCTURE = "ANADARKO_09_MARMATON_STRUCTURE";
export const ANADARKO_10_CHEROKEE_STRUCTURE = "ANADARKO_10_CHEROKEE_STRUCTURE";
export const ANADARKO_11_ATOKA_STRUCTURE = "ANADARKO_11_ATOKA_STRUCTURE";
export const ANADARKO_12_TOP_EROSION_STRUCTURE =
  "ANADARKO_12_TOP_EROSION_STRUCTURE";
export const ANADARKO_13_SPRINGER_STRUCTURE = "ANADARKO_13_SPRINGER_STRUCTURE";
export const ANADARKO_14_CHESTER_LIME_STRUCTURE =
  "ANADARKO_14_CHESTER_LIME_STRUCTURE";
export const ANADARKO_15_MISSISSIPPI_LIME_STRUCTURE =
  "ANADARKO_15_MISSISSIPPI_LIME_STRUCTURE";
export const ANADARKO_16_WOODFORD_STRUCTURE = "ANADARKO_16_WOODFORD_STRUCTURE";
export const ANADARKO_17_HOSSTON_STRUCTURE = "ANADARKO_17_HOSSTON_STRUCTURE";
export const ANADARKO_18_SYLVAN_SHALE_STRUCTURE =
  "ANADARKO_18_SYLVAN_SHALE_STRUCTURE";
export const ANADARKO_19_VIOLA_STRUCTURE = "ANADARKO_19_VIOLA_STRUCTURE";
export const ANADARKO_20_SIMPSON_STRUCTURE = "ANADARKO_20_SIMPSON_STRUCTURE";
export const ANADARKO_21_ARBUCKLE_STRUCTURE = "ANADARKO_21_ARBUCKLE_STRUCTURE";
export const WOODFORD_LIMIT = "WOODFORD_LIMIT";
export const STRUCTURAL_ELEMENTS = "STRUCTURAL_ELEMENTS";
export const SCOOP_STACK_PLAY_AREAS = "SCOOP_STACK_PLAY_AREAS";
export const WOODFORD_ISOPACH = "WOODFORD_ISOPACH";
export const WOODFORD_STRUCTURE = "WOODFORD_STRUCTURE";
export const WOODFORD_FIELDS = "WOODFORD_FIELDS";
export const WOODFORD_MATURITY = "WOODFORD_MATURITY";

export const STORAGE_UNITS_LAYERS = {
  AUSTIN: "AUSTIN",
  TAYLOR: "TAYLOR",
  SMACKOVER: "SMACKOVER",
  VICKSBURG: "VICKSBURG",
  BOSSIER: "BOSSIER",
  COTTONVALLEY: "COTTONVALLEY",
  TRINITY: "TRINITY",
  EAGLEMILLS: "EAGLEMILLS",
  CLAIBORNE: "CLAIBORNE",
  PALUXY: "PALUXY",
  NORPHLET: "NORPHLET",
  LR_CRETA: "LR_CRETA",
  PETTET: "PETTET",
  LRCOTTONVALLEY: "LRCOTTONVALLEY",
  LR_TUSCA: "LR_TUSCA",
  HAYNESVILLE: "HAYNESVILLE",
  UP_WILCOX: "UP_WILCOX",
  SLIGO: "SLIGO",
  LRSMACKOVER: "LRSMACKOVER",
  HOSSTON_TRAVIS_PEAK: "HOSSTON_TRAVIS_PEAK",
  RODESSA: "RODESSA",
  LR_WILCOX: "LR_WILCOZ",
};

export const CARTO_LAYER_GROUP_NAMES = [
  WELL_SPOTS,
  BOTTOM_WELL_SPOTS,
  WELL_PATH_AND_STICKS,
  BUBBLE_MAP,
  PERMIT_SPOTS,
];

export const SURFACE_GEO_POINT = "SurfaceGeoPoint";
export const BOTTOM_GEO_POINT = "BottomGeoPoint";
export const WELL_PATHS_GEO_POINT = "WellsPath";
export const WELL_STICKS_GEO_POINT = "WellStickLine";

// Note: Only update this when accidentally changed layer name and saved it
// Used for LOADING SAVED SEARCH with outdated layer names
export const LAYER_OLD_TO_NEW_NAME_MAPPER: LayerNameMapper = {
  [WELL_SPOTS]: WELL_SPOTS,
  [BOTTOM_WELL_SPOTS]: BOTTOM_WELL_SPOTS,
  [WELL_PATH_AND_STICKS]: WELL_PATH_AND_STICKS,
  [BUBBLE_MAP]: BUBBLE_MAP,
  [LAND_GRIDS]: LAND_GRIDS,
  [OFFSHORE_BLOCKS]: OFFSHORE_BLOCKS,
  [TGS_SURVEYS]: TGS_SURVEYS,
  [STRATIGRAPHIC_MODELS_AREA]: STRATIGRAPHIC_MODELS_AREA,
  [MAGNETIC_ALY]: MAGNETIC_ALY,
  [SHADED_ALY]: SHADED_ALY,
  [ISOSTATIC_ALY]: ISOSTATIC_ALY,
  [BOUGUER_ALY]: BOUGUER_ALY,
  [TRENDS_OUTLINE]: TRENDS_OUTLINE,
  [TRENDS]: TRENDS,
  [MAJOR_BASINS]: MAJOR_BASINS,

  // Outdated layer names that was saved
  "TGS Surveys": TGS_SURVEYS,
  "Trends Outline": TRENDS_OUTLINE,
  Trends: TRENDS,
  "Major Basins": MAJOR_BASINS,
};

// Note: Update this when changing the label in map settings
// Mapping for layer name to the displayed label
export const LAYER_NAME_TO_LABEL_MAPPER: LayerNameMapper = {
  [WELL_SPOTS]: WELL_SPOTS,
  [BOTTOM_WELL_SPOTS]: BOTTOM_WELL_SPOTS,
  [WELL_PATH_AND_STICKS]: WELL_PATH_AND_STICKS,
  [BUBBLE_MAP]: BUBBLE_MAP,
  [BOUGUER_ALY]: "Anomaly: Bouguer",
  [ISOSTATIC_ALY]: "Anomaly: Isostatic",
  [MAGNETIC_ALY]: "Anomaly: Magnetic",
  [SHADED_ALY]: "Anomaly: Shaded",
  [CANADA_LAND_GRIDS]: "Canada Land Grid",
  [LAND_GRIDS]: "Land Grid by GVERSE GeoGraphix \u00AE",
  [MAP_SEARCH_PIPELINES]: "Pipelines by MAPSearch\u2122",
  [OFFSHORE_BLOCKS]: "Offshore Blocks by GVERSE GeoGraphix \u00AE",
  [MAJOR_BASINS]: MAJOR_BASINS,
  [TGS_SURVEYS]: TGS_SURVEYS,
  [STRATIGRAPHIC_MODELS_AREA]: "TGS Stratigraphic Model Outlines",
  [TRENDS]: TRENDS,
  [TRENDS_OUTLINE]: TRENDS_OUTLINE,
  [GPB_01_RUSTLER]: "01 Rustler",
  [GPB_02_SALADO]: "02 Salado",
  [GPB_03_BELL_CANYON_TANSIL]: "03 Bell Canyon-Tansil",
  [GPB_03A_CHERRY_CANYON_QUEEN]: "03A Cherry Canyon-Queen",
  [GPB_04_BRUSHY_CANYON_SAN_ANDRES]: "04 Brushy Canyon-San Andreas",
  [GPB_04A_GLORIETA]: "04A Glorieta",
  [GPB_05_TOP_BS_CLEARFORK]: "05 Top Bone Spring-Clearfork",
  [GPB_06_1ST_BS_SAND_WICHITA_SPRABERRY]:
    "06 1st Bone Spring Sand-Wichita-Upper Spraberry",
  [GPB_06A1_MIDDLE_SPRABERRY]: "06A Middle Spraberry",
  [GPB_06A2_2ND_BONE_SPRING_LIME]: "06A 2nd Bone Spring Lime",
  [GPB_06B1_LOWER_SPRABERRY]: "06B Lower Spraberry",
  [GPB_06B2_2ND_BONE_SPRING_SAND]: "06B 2nd Bone Spring Sand",
  [GPB_06C0_LOWER_SPRABERRY_SHALE]: "06C Lower Spraberry Shale",
  [GPB_06C1_3RD_BONE_SPRING_LIME]: "06C1 3rd Bone Spring Lime",
  [GPB_06C2_3RD_BONE_SPRING_LIME_SANDSTONE_TOP]:
    "06C2 3rd Bone Spring Lime Sand Top",
  [GPB_06C3_3RD_BONE_SPRING_LIME_SANDSTONE_BASE]:
    "06C3 3rd Bone Spring Lime Sand Base",
  [GPB_06D_3RD_BONE_SPRING_SAND]: "06D 3rd Bone Spring Sand",
  [GPB_06D_DEAN_SAND]: "06D Dean Sand",
  [GPB_07_TOP_WOLFCAMP]: "07 Top Wolfcamp",
  [GPB_07A1_WOLFCAMP_X]: "07A1 Wolfcamp X",
  [GPB_07A2_WOLFCAMP_Y]: "07A2 Wolfcamp Y",
  [GPB_07A3_WOLFCAMP_SHALE]: "07A3 Wolfcamp Shale",
  [GPB_07B1_MID_WOLFCAMP_B]: "07B Midland Wolfcamp B",
  [GPB_07B2_DEL_WOLFCAMP_B]: "07B Delaware Wolfcamp B",
  [GPB_07C_INTRA_WOLFCAMP_C]: "07C Middle Wolfcamp-Intra Wolfcamp-Wolfcamp C",
  [GPB_08_CISCO_WOLFCAMP_D]: "08D Cisco-Wolfcamp D",
  [GPB_09_STRAWN]: "09 Strawn",
  [GPB_10_BARNETT]: "10 Barnett",
  [GPB_11_MISSISSIPPIAN_LIMESTONE]: "11 Mississippi Lime",
  [GPB_12_WOODFORD_SHALE]: "12 Woodford Shale",
  [GPB_13_DEVONIAN_CARBONATE]: "13 Devonian Carbonate",
  [GPB_14_SILURIAN_SHALE]: "14 Silurian Shale",
  [GPB_15_FUSSLEMAN]: "15 Fusselman",
  [GPB_16_SIMPSON]: "16 Simpson",
  [GPB_17_ELLENBURGER]: "17 Ellenburger",
  [GPB_18_BASEMENT]: "18 Basement",
  [MID_15A_MONTOYA]: "15A Montoya",
  [FIRST_ST_BONE_SPRINGS_ISOPACH]: "06 1st Bone Spring Sand",
  [SECOND_BONE_SPRINGS_ISOPACH]: "06A 2nd Bone Spring Sand",
  [THIRD_BONE_SPRINGS_ISOPACH]: "06D 3rd Bone Spring Sand",
  [WOLFCAMPX_ISOPACH]: "07A1 Wolfcamp X",
  [UPPERSPRABERRY_ISOPACH]: "06 Upper Spraberry",
  [MIDDLESPRABERRY_ISOPACH]: "06A Middle Spraberry",
  [LOWERSPRABERRY_ISOPACH]: "06B Lower Spraberry",
  [SANANDRES_ISOPACH]: "04 San Andreas",
  [WOLFCAMPY_ISOPACH]: "07A2 Wolfcamp Y",
  [EAGLE_FORD_02_VICKSBURG_GROUP_GRID]: "02 Vicksburg",
  [EAGLE_FORD_03_CLAIBORNE_GROUP_GRID]: "03 Lower Claiborne",
  [EAGLE_FORD_03B_LOWER_CLAIBORNE_GRID]: "04 Upper Claiborne",
  [EAGLE_FORD_04_UPPER_WILCOX_GRID]: "05 Upper Wilcox",
  [EAGLE_FORD_04A_MIDDLE_WILCOX_GRID]: "06 Middle Wilcox",
  [EAGLE_FORD_04B_LOWER_WILCOX_GRID]: "07 Lower Wilcox",
  [EAGLE_FORD_05_MIDWAY_GROUP_GRID]: "08 Midway",
  [EAGLE_FORD_06_NAVARRO_GROUP_GRID]: "09 Navarro",
  [EAGLE_FORD_07_TAYLOR_GROUP_GRID]: "10 Taylor",
  [EAGLE_FORD_07A_ANACACHO_GRID]: "11 Anacacho Lime",
  [EAGLE_FORD_08_AUSTIN_CHALK_GRID]: "12 Austin Chalk",
  [EAGLE_FORD_09_UPPER_EAGLE_FORD_GRID]: "13 Upper Eagle Ford",
  [EAGLE_FORD_10_LOWER_EAGLE_FORD_GRID]: "14 Lower Eagle Ford",
  [EAGLE_FORD_10A_WOODBINE_GRID]: "15 Woodbine",
  [EAGLE_FORD_10B_MANESS_GRID]: "16 Maness Shale",
  [EAGLE_FORD_11_BUDA_GRID]: "17 Buda Lime",
  [EAGLE_FORD_11A_DEL_RIO_GRID]: "18 Del Rio",
  [EAGLE_FORD_11B_GEORGETOWN_GRID]: "19 Georgetown Lime",
  [EAGLE_FORD_11C_EDWARDS_GRID]: "20 Edwards Lime",
  [EAGLE_FORD_12_GLEN_ROSE_GRID]: "21 Glen Rose",
  [EAGLE_FORD_13_PEARSAL_GRID]: "22 Pearsall Shale",
  [EAGLE_FORD_14_SLIGO_PETTET_GRID]: "23 Sligo-Pettet",
  [ANADARKO_01_STONE_CORRAL_STRUCTURE]: "01 Stone Corral",
  [ANADARKO_02_TOP_HUTCHINSON_STRUCTURE]: "02 Top Hutchinson Salt",
  [ANADARKO_03_BASE_HUTCHINSON_STRUCTURE]: "03 Base Hutchinson Salt",
  [ANADARKO_04_CHESTER_STRUCTURE]: "04 Chase",
  [ANADARKO_05_WABAUNSEE_STRUCTURE]: "05 Wabaunsee",
  [ANADARKO_06_HEEBNER_SHALE_STRUCTURE]: "06 Heebner Shale",
  [ANADARKO_07_LANSING_STRUCTURE]: "07 Lansing",
  [ANADARKO_08_HOXBAR_KANSAS_CITY_STRUCTURE]: "08 Hoxbar-Kansas City",
  [ANADARKO_09_MARMATON_STRUCTURE]: "09 Marmaton",
  [ANADARKO_10_CHEROKEE_STRUCTURE]: "10 Cherokee",
  [ANADARKO_11_ATOKA_STRUCTURE]: "11 Atoka",
  [ANADARKO_12_TOP_EROSION_STRUCTURE]: "12 Morrow",
  [ANADARKO_13_SPRINGER_STRUCTURE]: "13 Springer",
  [ANADARKO_14_CHESTER_LIME_STRUCTURE]: "14 Chester",
  [ANADARKO_15_MISSISSIPPI_LIME_STRUCTURE]: "15 Mississippi Lime",
  [ANADARKO_16_WOODFORD_STRUCTURE]: "16 Woodford Shale",
  [ANADARKO_17_HOSSTON_STRUCTURE]: "17 Hunton",
  [ANADARKO_18_SYLVAN_SHALE_STRUCTURE]: "18 Sylvan Shale",
  [ANADARKO_19_VIOLA_STRUCTURE]: "19 Viola",
  [ANADARKO_20_SIMPSON_STRUCTURE]: "20 Simpson",
  [ANADARKO_21_ARBUCKLE_STRUCTURE]: "21 Arbuckle",
  [WOODFORD_LIMIT]: "Woodford Limit",
  [STRUCTURAL_ELEMENTS]: "Structural Elements",
  [SCOOP_STACK_PLAY_AREAS]: "SCOOP STACK Play Areas",
  [WOODFORD_ISOPACH]: "Woodford Isopach",
  [WOODFORD_STRUCTURE]: "Woodford Structure",
  [WOODFORD_FIELDS]: "Woodford Fields",
  [WOODFORD_MATURITY]: "Woodford Maturity",
  [STORAGE_UNITS_LAYERS.AUSTIN]: "Austin",
  [STORAGE_UNITS_LAYERS.TAYLOR]: "Taylor",
  [STORAGE_UNITS_LAYERS.SMACKOVER]: "Smackover",
  [STORAGE_UNITS_LAYERS.VICKSBURG]: "Vicksburg",
  [STORAGE_UNITS_LAYERS.BOSSIER]: "Bossier",
  [STORAGE_UNITS_LAYERS.COTTONVALLEY]: "Cotton Valley",
  [STORAGE_UNITS_LAYERS.TRINITY]: "Trinity",
  [STORAGE_UNITS_LAYERS.EAGLEMILLS]: "Eagle Mills",
  [STORAGE_UNITS_LAYERS.CLAIBORNE]: "Claiborne",
  [STORAGE_UNITS_LAYERS.PALUXY]: "Paluxy",
  [STORAGE_UNITS_LAYERS.NORPHLET]: "Norphlet",
  [STORAGE_UNITS_LAYERS.LR_CRETA]: "Lr Cretaceous",
  [STORAGE_UNITS_LAYERS.PETTET]: "Pettet",
  [STORAGE_UNITS_LAYERS.LRCOTTONVALLEY]: "Lr Cotton Valley",
  [STORAGE_UNITS_LAYERS.LR_TUSCA]: "Lr Tuscaloosa",
  [STORAGE_UNITS_LAYERS.HAYNESVILLE]: "Haynesville",
  [STORAGE_UNITS_LAYERS.UP_WILCOX]: "Up Wilcox",
  [STORAGE_UNITS_LAYERS.SLIGO]: "Sligo",
  [STORAGE_UNITS_LAYERS.LRSMACKOVER]: "Lr Smackover",
  [STORAGE_UNITS_LAYERS.HOSSTON_TRAVIS_PEAK]: "Hosston-TravisPeak",
  [STORAGE_UNITS_LAYERS.RODESSA]: "Rodessa",
  [STORAGE_UNITS_LAYERS.LR_WILCOX]: "Lr Wilcox",
};

export const TGS_THEME_HEX_CODE = [
  "#003660",
  "#F7D45E",
  "#00AAAA",
  "#6E6F71",
  "#7CAAC0",
  "#07365E",
];

export const OL_MIN_ZOOM = 3;
export const OL_MAX_ZOOM = 18;
export const CARTO_MIN_ZOOM = 2;
export const CARTO_MAX_ZOOM = 18;
export const CARTO_SHALLOW_MIN_ZOOM = 11;

export const SELECTED_LINE = [170, 0, 113];
export const UNSELECTED_LINE = [136, 0, 113, 0];
export const HOVER_WELL_PATH_AND_STICK = [66, 165, 245];

export const YEAR_DATE_FORMAT = "YYYY-MM-DD";
export const YEAR_FORMAT = "YYYY";

export const dotFiller =
  "....................................................................................................";
export const noData = "NA";
export const specialFloat = "special-float";
export const tabStyleTable = "table";
export const tabStyleField = "field";
export const trueComparison = "true";
export const TRUE_FLAG = true;

export const FILE_EXTENSION_EXCEL = ".xlsx";
export const FILE_TYPE = "";
export const EXCEL = "xlsx";
export const ARRAY_TYPE = "array";
export const WELL_CARD_POSITION_ARRAY = {
  DEFAULT: { x: 500, y: 50 },
  OFFSET: 50,
  FIX_OFFSET: 250,
};

export const ATTRIBUTES_TAB = 0;
export const UWI_TAB = 1;
export const FORECAST_CHECK = "-f";
export const CDATA = "cdata";
export const MDATA = "mdata";
export const FORECAST = "Forecast";

export const EXPORT_TYPE = {
  DEFAULT: "DEFAULT",
  DATA_GRID: "DATAGRID",
};

export const UWI_UPLOAD_EXTENSIONS = ".txt,.csv";
export const PARETO_DISTRIBUTOR_COMPENSATOR = 1.375;

export const MIN_LATITUDE = -90;
export const MAX_LATITUDE = 90;
export const MIN_LONGITUDE = -180;
export const MAX_LONGITUDE = 180;

export const MAX_EXPORT = 100000;

export const UWI_MAX_EXPORT = 1500000;

export const WELLNAME_MAX_LENGTH = 10;

export const WELLNAME_CARD_MAX_LENGTH_LOWERCASE = 30;
export const WELLNAME_CARD_MAX_LENGTH_UPPERCASE = 23;

export const DRAW_TO_SELECT_MAX_SELECTION = 400000;
export const DRAW_TO_SELECT_MAX_ALERT_MESSAGE =
  "Max 400k wells when selecting from the map";

export const VECTOR_TYPE_WELLS = [
  DYNAMIC_WELL_SPOTS,
  DYNAMIC_BOTTOM_WELL_SPOTS,
  WELL_PATHS,
  WELL_STICKS,
];

export const WELL_DVT_LAYERS = [
  WELL_SPOTS,
  BOTTOM_WELL_SPOTS,
  WELL_PATH_AND_STICKS,
  BUBBLE_MAP,
];

export const PERMIT_DVT_LAYERS = [PERMIT_SPOTS];

//------------- Help and Data Dictionary constants
export const NEW_TAB = "_blank";
export const SELF = "_self";
export const HELP = "Help";
export const HELP_HTML_PATH = "static/help/index.htm";
export const DATA_DICTIONARY = "Data Dictionary";
export const DATA_DICTIONARY_HTML_PATH = "static/datadictionary/index.htm";
export const DOWNLOADABLE_MATERIALS_MENU_TITLE = "Help & Documentation";

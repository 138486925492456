import { useEffect, useState } from "react";

import { Drawer, IconButton } from "@mui/material";

import classNames from "classnames";

import { SIDEBAR_MENU, SIDEBAR_MENU_TEXTS } from "../../constants/panels/menu";
import { SHARE_SEARCH_RIGHT_MENU_PLACEMENT } from "../../constants/panels/shareLink";

import useExportCardStore from "../../store/exportCard/exportCardStore";
import usePanelsStore from "../../store/panels/panelsStore";

import useSideBarNavPanel from "../../customHooks/panels/useSideBarNavPanel";
import useSearchPanel from "../../customHooks/search/useSearchPanel";

import Loading from "../common/Loading";
import { CustomTooltip } from "../mapSettingsAccordions/CustomTooltip";
import SidebarWellPanelNav from "./SidebarWellPanelNav";
import PanelControls from "./common/PanelControl";
import ShareSearchMenu from "./common/ShareSearchMenu";
import DashboardChartsPanel from "./dashboardChartsPanel/DashboardChartsPanel";
import ExportPackageManager from "./exportManager/ExportPackageManager";
import SavedSearchPanel from "./savedSearchPanel/SavedSearchPanel";
import SearchPanel from "./searchPanel/SearchPanel";
import WellPanelDrawer from "./wellPanel/WellPanelDrawer";

const SidebarNavPanel = () => {
  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const activePanel = usePanelsStore((state) => state.activePanel);
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);

  const exportManagerLoading = useExportCardStore(
    (state) => state.exportManagerLoading
  );

  const [panelRef, setPanelRef] = useState<HTMLDivElement | null>(null);

  const {
    openSidebarPanel,
    wellsAndPermitPanel,
    handleMenuClick,
    handleCloseMenuClick,
    getIsDisabledMenu,
    shareSearchAnchorEl,
    openShareSearchMenu,
    setShareSearchAnchorEl,
  } = useSideBarNavPanel();

  const { searchTypeByActiveTab, isFilterIncluded } = useSearchPanel();

  useEffect(() => {
    if (
      !wellsAndPermitPanel.length &&
      activePanel === SIDEBAR_MENU_TEXTS.WELL_PANEL
    ) {
      updateActivePanel(SIDEBAR_MENU_TEXTS.DEFAULT_CHARTS);
    }
  }, [wellsAndPermitPanel.length]);

  return (
    <>
      <Drawer
        ref={setPanelRef}
        anchor="left"
        open={isOpenAppLevelNav}
        className="sidebar-drawer-style"
        disableEnforceFocus
        id="app-level-navigation-drawer"
        hideBackdrop
      >
        <div className="sidebar-menu">
          {SIDEBAR_MENU.map((menu, index) => {
            if (
              menu.text === SIDEBAR_MENU_TEXTS.SHARE_SEARCH &&
              getIsDisabledMenu(menu.text)
            ) {
              return (
                <CustomTooltip
                  key={index}
                  title="Search required to share"
                  placement="right"
                  className="custom-tooltip-share-search"
                  arrow
                >
                  <span>
                    <IconButton
                      key={menu.text}
                      sx={{
                        ...menu.style,
                      }}
                      className={"menu-icon-box"}
                      disabled={getIsDisabledMenu(menu.text)}
                    >
                      {menu.icon}
                    </IconButton>
                  </span>
                </CustomTooltip>
              );
            } else {
              return (
                <IconButton
                  key={menu.text}
                  sx={{
                    ...menu.style,
                  }}
                  className={classNames("menu-icon-box", {
                    "active-menu": Boolean(
                      activePanel === menu.text &&
                        activePanel !== SIDEBAR_MENU_TEXTS.SHARE_SEARCH &&
                        !shareSearchAnchorEl
                    ),
                    "share-active-button": Boolean(
                      shareSearchAnchorEl &&
                        menu.text === SIDEBAR_MENU_TEXTS.SHARE_SEARCH
                    ),
                  })}
                  onClick={(e) => handleMenuClick(menu.text, e)}
                  disabled={getIsDisabledMenu(menu.text)}
                >
                  {menu.text === SIDEBAR_MENU_TEXTS.EXPORT_PACKAGE_MANAGER &&
                  exportManagerLoading ? (
                    <Loading size={20} />
                  ) : (
                    menu.icon
                  )}
                </IconButton>
              );
            }
          })}
          {wellsAndPermitPanel.length !== 0 && <SidebarWellPanelNav />}
        </div>
        {!isOpenLeftSidePanel && (
          <PanelControls
            anchorEl={panelRef}
            open={isOpenLeftSidePanel}
            clickCallback={() => {
              handleCloseMenuClick();
            }}
          />
        )}
      </Drawer>
      <DashboardChartsPanel
        open={openSidebarPanel.dashboardPanel}
        handleCloseMenuClick={handleCloseMenuClick}
      />
      <SearchPanel
        open={openSidebarPanel.searchPanel}
        handleCloseMenuClick={handleCloseMenuClick}
      />
      <SavedSearchPanel
        open={openSidebarPanel.savedSearchPanel}
        handleCloseMenuClick={handleCloseMenuClick}
      />
      <WellPanelDrawer
        isOpenWellPanel={openSidebarPanel.wellPanel}
        handleCloseMenuClick={handleCloseMenuClick}
      />
      <ExportPackageManager />

      <ShareSearchMenu
        open={openShareSearchMenu}
        anchorEl={shareSearchAnchorEl}
        handleClose={() => setShareSearchAnchorEl(null)}
        searchType={searchTypeByActiveTab}
        isFilterIncluded={isFilterIncluded}
        placement={SHARE_SEARCH_RIGHT_MENU_PLACEMENT}
      />
    </>
  );
};

export default SidebarNavPanel;

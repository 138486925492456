import { useEffect, useState } from "react";

import { Check } from "@mui/icons-material";

import { UnitOfMeasure } from "../../../types/common/appMode/appMode";

import { UNIT_OF_MEASURE_MENU_OPTION } from "../../../constants/appHeader/unitOfMeasure";

import useAppModeStore from "../../../store/common/appModeStore";

import useUnitOfMeasure from "../../../customHooks/common/useUnitOfMeasure";
import useSearchCriteria from "../../../customHooks/search/useSearchCriteria";

import CustomCircularButton from "../../common/CustomCircularButton";
import CustomMenuSelection from "../../common/CustomMenuSelection";
import SettingsIcon from "../../common/icons/SettingsIcon";

const UoMSetting = () => {
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSettings = Boolean(anchorEl);
  const UoMValueOnSelection = useAppModeStore(
    (state) => state.UoMValueOnSelection
  );
  const updateUoMValueOnSelection = useAppModeStore(
    (state) => state.updateUoMValueOnSelection
  );
  const UoMValueOnSearch = useAppModeStore((state) => state.UoMValueOnSearch);

  const updateUoMAlertState = useAppModeStore(
    (state) => state.updateUoMAlertState
  );

  const { hasSearchCriteria } = useSearchCriteria();

  const { getUoMLabel } = useUnitOfMeasure();

  const onButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsButtonActive(!isButtonActive);
    setAnchorEl(e.currentTarget);
  };

  const OnButtonBlur = () => {
    setIsButtonActive(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (selected: string) => {
    updateUoMValueOnSelection(selected as UnitOfMeasure);
    handleClose();
  };

  useEffect(() => {
    if (hasSearchCriteria && UoMValueOnSelection !== UoMValueOnSearch) {
      //NOTE:  The warning toast will go away only if the user clicks on ‘Search’ button or clicks on ‘x’ (NOT by default after 3 seconds).
      updateUoMAlertState({
        message: `You have changed the unit of measurement to ${getUoMLabel(
          UoMValueOnSelection
        )}. Please rerun the search to reflect the changes.`,
        severity: "warning",
      });
    }
  }, [hasSearchCriteria, UoMValueOnSelection]);

  return (
    <>
      <CustomCircularButton
        isActive={isButtonActive || openSettings}
        onClick={onButtonClick}
        onBlur={OnButtonBlur}
      >
        <SettingsIcon />
      </CustomCircularButton>
      <CustomMenuSelection
        options={UNIT_OF_MEASURE_MENU_OPTION}
        selectedItem={UoMValueOnSelection}
        selectionIndicator={<Check className="uom-check-icon" />}
        open={openSettings}
        anchorEl={anchorEl}
        onItemClick={handleClick}
        onClose={handleClose}
      />
    </>
  );
};

export default UoMSetting;

import { OL_MAX_ZOOM } from "../constants/constants";

import { baseMapURLs, layerDefinitions } from "../constants";

const GIS_URL = "https://map-preview.datalake.tgs.com/dev/MapServiceProxy";
const LAYER_PREFIX = "saga_dev";
const CARTO_CONNECTION = "sa-carto-saga-analytics";
const BQ_TABLE = "tgs-production-data-dev-7e18";
const WELL_SERVICE = "https://well-data-analytics-test.api.tgs.ai/";
const EXPORT_MANAGER = "https://export-test.datalake.tgs.com";
const OKTA_SERVICE = "TgsGlobalTest";
const FILE_BUCKET = "fileservicestest";
const R360_URL = "https://test.tgsr360.com/searchExternal";
const R360LAS_URL = "https://test.tgsr360.com/lasWorxExternal";
const DOCS_VIEWER_URL = "https://d1l0zyrs0mwpim.cloudfront.net";
const DOWNLOADABLE_MATERIALS_URL =
  "https://docs.tgs.ai/WellDataAnalytics/Help/WDA_TrainingGuide_07242024.pdf";
const SURFACE_WELL_SPOTS_TABLE = "view_surface_locations";
const BOTTOM_WELL_SPOTS_TABLE = "view_bottom_locations";
const WELL_PATHS_TABLE = "TBL_WELLSPATH";
const WELL_STICKS_TABLE = "tbl_wellstick";

export default {
  name: "default",
  roboHelpPageViewTitle: "Robo Help - TEST",
  localStorageAllowed: true,
  searchPageLimit: 10000,
  countPageLimit: 75000,
  maximumPolygonsToImport: 1,
  permitsEnabled: true,
  documentTitle: "Well Data Analytics - Test",
  wellCardProdPlotEnabled: true,
  r360ExportEnabled: true,
  isStratigraphicEnabled: true,
  isGeoEdgesEnabled: true,
  isCarbonStorageEnabled: true,
  isMapSearchPipelineEnabled: true,
  isWDAHelpEnabled: false,
  isDataDictionaryEnabled: false,
  isWellEconomicsEnabled: true,
  isDownloadableMaterialsEnabled: true,
  hasCanadaWells: true,
  isScheduledExportsEnabled: false,
  isUoMEnabled: true,
  termsAndConditionValues: {
    url: "https://windaxiom.s3.amazonaws.com/WindAxiomTrialLicense-v7Feb2022.pdf",
    currentVersion: "v7Feb2022",
  },
  exportServiceAPIKey: "FFBFCE9E-58F8-42CF-98C8-E0B5D945FE74",
  muiXGridPremiumLicense:
    "32a73659d2a1b2ed823c7179e5882df0Tz03OTE3MSxFPTE3MzIyMTgyMTYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
  oidc: {
    clientId: "0oa1chlt3xaQ4BxM80h8",
    issuer: "https://login-dev.tgsnopec.com/oauth2/auspth6n0r1gXj1tV0h7", // verify the issuer is correct
    scope: [
      "openid",
      "profile",
      "email",
      "address",
      "phone",
      "offline_access",
      "TgsGlobalDevelopment.ProductDelivery",
      `${OKTA_SERVICE}.FileServices`,
      "SagaAnalyticsApi",
      "R360-UI-Scope",
      "R360-API-Scope",
      "TgsGlobalTest.ProductDelivery",
      "TgsGlobalDevelopment.DataLake",
      "TgsGlobalDevelopment.DataLake.WellFilePage",
    ],
    allowedGroups: [
      "AP-Arlas-Service",
      "NA-Arlas-Service",
      "EU-Arlas-Service",
      "TgsGlobalDevelopment.ProductDelivery",
    ],
  },
  tables: {
    cartoWellPerformanceHeaderTable: `${BQ_TABLE}.Saga.WellPerformanceHeader`,
  },
  endpoints: {
    ...baseMapURLs,

    // TGS Specific
    tgsWFS: `${GIS_URL}/geoserver/nes_wind_misc/wfs`,
    tgsNesWindWMS: `${GIS_URL}/geoserver/nes_wind_misc/wms`,
    tgsGWCWMTS: {
      url: `${GIS_URL}/geoserver/gwc/service/wmts`,
      layerName: `${LAYER_PREFIX}:states`,
      format: "application/vnd.mapbox-vector-tile",
      infoFormat: "text/html",
    },
    exportManager: EXPORT_MANAGER,
    // SAGA Specific
    wellService: WELL_SERVICE,
    WellLocationCache:
      "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Standard/WellLocationCacheMultiLayer/MapServer",
    WellLocationCacheProxy:
      "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx",
    basinLayer:
      "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Premium/TGS_Formations/MapServer",
    basinLayerProxy: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx",
    presignedURL: `https://api-workshop.tgs.com/Test/FileServices/FileUpload`,
    productDelivery: `https://api-workshop.tgs.com/Test/ProductDelivery`,
    dataLake: `https://api-workshop.tgs.com/Dev/DataLake`,
    fileUploadFolderPath: `gs://${FILE_BUCKET}/TTL365Day_SagaFileUpload/`,
  },
  cartoBaseURL: `${GIS_URL}`,
  cartoTokenBaseURL: `${GIS_URL}/token/GetCartoAccessToken`,
  defaultViewSettings: {
    //190:15  error  This number literal will lose precision at runtime @typescript-eslint/no-loss-of-precision
    center: [-11226323, 3549257],
    // center: [-11226322.827908971, 3549256.523751779],
    zoom: 4,
    maxZoom: OL_MAX_ZOOM,
  },
  layerDefinitions: layerDefinitions(
    GIS_URL,
    CARTO_CONNECTION,
    LAYER_PREFIX,
    BQ_TABLE
  ),
  BQ_TABLE,
  R360_URL,
  R360LAS_URL,
  DOCS_VIEWER_URL,
  DOWNLOADABLE_MATERIALS_URL,
  SURFACE_WELL_SPOTS_TABLE,
  BOTTOM_WELL_SPOTS_TABLE,
  WELL_PATHS_TABLE,
  WELL_STICKS_TABLE,
};

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, LinearProgress } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridColumnHeaderParams,
  GridColumnOrderChangeParams,
  GridFilterModel,
  GridRowId,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

import classNames from "classnames";
import { debounce, isEqual } from "lodash";

import { setTimeoutType } from "../../types/common/general";
import { WellData } from "../../types/common/wells";
import { VisibleColumns } from "../../types/grid/export";
import { SelectedKeys } from "../../types/map/selection/store";

import config from "../../configs/appSettings";

import {
  RESET_GRID_TRIGGERS,
  WELL_SELECT_DRAW_TRIGGER,
  WELL_SELECT_GRID_TRIGGER,
  WELL_SELECT_MAP_TRIGGER,
} from "../../constants/grid/gridTrigger";

import useChartStore from "../../store/chart/chartStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import usePanelsStore from "../../store/panels/panelsStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { useChartModule } from "../../customHooks/charts/useChartModule";
import { useContainerDimensions } from "../../customHooks/common/useContainerDimentions";
import {
  useGridData,
  useGridFilter,
  useGridInfiniteLoader,
  useGridSelectRow,
  useGridSort,
} from "../../customHooks/grid";
import { useGridSelectedData } from "../../customHooks/grid/useGridSelectedData";
import { useGridSortBySelected } from "../../customHooks/grid/useGridSortBySelected";
import useGridWellPanel from "../../customHooks/grid/useGridWellPanel";
import { useModularity } from "../../customHooks/modularity/useModularity";
import useAllWellDataForExport from "../../customHooks/useAllWellDataForExport";
import usePrevious from "../../customHooks/usePrevious";

import {
  allColumns,
  defaultFilterModel,
  getGridClassName,
  getGridContainerClassName,
  getHeaderClassName,
} from "../../utils/datagrid/";
import { getUWIByProperties } from "../../utils/map/gis/common";

import CustomColumnMenu from "./CustomGridComponents/ColumnMenu/ColumnMenu";
import Footer from "./CustomGridComponents/Footer";
import GridPlotHeader from "./CustomGridComponents/Header/GridPlotHeader";
import HeaderFilterIconButton from "./CustomGridComponents/HeaderFilterIcon";
import NoResultDisplay from "./CustomGridComponents/NoResultDisplay";

const DataGrid = () => {
  const pageLimit = config.searchPageLimit;
  const rowSize = 500;
  const handleGridWellSelectionRef = useRef(false);
  const mapWellSelectNotInGridRef = useRef({
    isMapClicked: false,
    isHeaderClicked: false,
  });
  const isGridFilteredRef = useRef(false);
  const gridRef = useRef(null);
  const apiRef = useGridApiRef();
  const [allWells, setAllWells] = useState<WellData[]>([]); // will contain all wells by batch (10K, 20k, ...)
  const [loadedWellData, setLoadedWellData] = useState<WellData[]>([]); // will only contain wells loaded in grid by row size (currently, 500)
  const [currentRows, setCurrentRows] = useState(rowSize);
  const [hasMore, setHasMore] = useState(false);
  const [totalFetchedRows, setTotalFetchedRows] = useState(0);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [sortBySelectedDataFromApi, setSortBySelectedDataFromApi] =
    useState(false);
  const setTimeOutRef = useRef<setTimeoutType | null>(null);

  const allSelectedGridData = useMapSelectionStore(
    (state) => state.allSelectedGridData
  );
  const selectedWellIds = useMapSelectionStore(
    (state) => state.selectedWellIds
  );
  const selectedPermitIds = useMapSelectionStore(
    (state) => state.selectedPermitIds
  );
  const selectedWellData = useMapSelectionStore(
    (state) => state.selectedWellData
  );
  const updateSelectedWellIds = useMapSelectionStore(
    (state) => state.updateSelectedWellIds
  );
  const updateSelectedPermitIds = useMapSelectionStore(
    (state) => state.updateSelectedPermitIds
  );
  const addSelectedWellIds = useMapSelectionStore(
    (state) => state.addSelectedWellIds
  );
  const addSelectedPermitIds = useMapSelectionStore(
    (state) => state.addSelectedPermitIds
  );
  const removeSelectedIds = useMapSelectionStore(
    (state) => state.removeSelectedIds
  );

  const updateSelectedWellUwis = useMapSelectionStore(
    (state) => state.updateSelectedWellUwis
  );
  const updateSelectedMapParentWellIDs = useMapSelectionStore(
    (state) => state.updateSelectedMapParentWellIDs
  );
  const updateSelectedBottomWellboreIDs = useMapSelectionStore(
    (state) => state.updateSelectedBottomWellboreIDs
  );

  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );

  const chartData = useChartStore((state) => state.chartData);

  const isFullScreenGrid = useDataGridStore((state) => state.isFullScreenGrid);
  const gridFilteredCount = useDataGridStore(
    (state) => state.gridFilteredCount
  );
  const batchWellGridData = useDataGridStore(
    (state) => state.batchWellGridData
  );
  const batchWellGridDataSuccess = useDataGridStore(
    (state) => state.batchWellGridDataSuccess
  );
  const batchWellGridDataLoading = useDataGridStore(
    (state) => state.batchWellGridDataLoading
  );
  const gridTotalCount = useDataGridStore((state) => state.gridTotalCount);
  const gridWellDataTrigger = useDataGridStore(
    (state) => state.gridWellDataTrigger
  );

  const searchCriteria = useDataGridStore((state) => state.searchCriteria);
  const sortModel = useDataGridStore((state) => state.sortModel);
  const columnsOrder = useDataGridStore((state) => state.columnsOrder);
  const updateColumnsOrder = useDataGridStore(
    (state) => state.updateColumnsOrder
  );
  const filterModel = useDataGridStore((state) => state.filterModel);
  const columnVisibilityModel = useDataGridStore(
    (state) => state.columnVisibilityModel
  );
  const allGridData = useDataGridStore((state) => state.allGridData);
  const allWellGridDataLoading = useDataGridStore(
    (state) => state.allWellGridDataLoading
  );
  const resetDefaultGridFilter = useDataGridStore(
    (state) => state.resetDefaultGridFilter
  );
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const isLoadingSavedSearchFileUpload = useUWISearchStore(
    (state) => state.isLoadingSavedSearchFileUpload
  );
  const sortByAnalysis = useDataGridStore((state) => state.sortByAnalysis);
  const updateSortByAnalysis = useDataGridStore(
    (state) => state.updateSortByAnalysis
  );
  const wellSelectTrigger = useDataGridStore(
    (state) => state.wellSelectTrigger
  );
  const updateWellSelectTrigger = useDataGridStore(
    (state) => state.updateWellSelectTrigger
  );
  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );

  const { error: gridDataError } = useGridData();
  const { sortGridCallback } = useGridSort();
  const { getNextBatchWellData, getNext500WellData } = useGridInfiniteLoader();
  const { getMapGridFilterData } = useGridFilter();
  const {
    selectedGridDataKeys,
    headerSelectCallback,
    setCheckboxHeaderDeterminate,
    setCheckboxHeaderInterdetminate,
    convertWellDataArrayToObject,
    isSelectedWellsInGrid,
    handleNewSelection,
  } = useGridSelectRow();
  const { exportDataAsExcelCallback } = useAllWellDataForExport();
  const { getGridSubContainerClassName } = useModularity();
  const { sortGridBySelected, getGridDataForSortBySelected } =
    useGridSortBySelected();
  const { wellPanelColumn } = useGridWellPanel();

  const { isSelectedAllGrid } = useGridSelectedData();

  const prevSelectedWellIdsKeys = usePrevious(selectedWellIdsKeys);

  const { handleWellChartUpdate, handleWellPermitChartUpdate } =
    useChartModule();

  const gridContainerRef = useRef(null);
  const { width } = useContainerDimensions(gridContainerRef);

  const handleGridWellSelection = useCallback(
    (newSelectedIds: GridRowId[]) => {
      // if well selected on map is not in grid && header is clicked
      // prevent the grid from selecting all wells
      if (
        mapWellSelectNotInGridRef.current.isMapClicked &&
        mapWellSelectNotInGridRef.current.isHeaderClicked
      )
        return;

      handleGridWellSelectionRef.current = true;

      const prevLength = selectedGridDataKeys.length;
      const newLength = newSelectedIds.length;

      updateWellSelectTrigger("grid");

      if (prevLength < newLength) {
        //ADD
        const IDsToAdd = newSelectedIds.filter(
          (id) => !selectedWellIds[id] && !selectedPermitIds[id]
        );
        const IDsObject: { [key: string]: boolean } = {};
        IDsToAdd.forEach((key) => {
          IDsObject[key] = true;
        });

        const newSelectionData = handleNewSelection(IDsObject);
        addSelectedWellIds(
          newSelectionData.newSelectedWellIdKeys,
          newSelectionData.newSelectedWellData,
          newSelectionData.newSelectedWellIds
        );
        addSelectedPermitIds(
          newSelectionData.newSelectedPermitIdKeys,
          newSelectionData.newSelectedPermitData,
          newSelectionData.newSelectedPermitIds
        );
      } else {
        //REMOVE
        const updatedSelection: { [key: GridRowId]: boolean } = {};
        newSelectedIds.forEach((key) => {
          updatedSelection[key] = true;
        });
        const keysToRemove = selectedGridDataKeys.filter(
          (key: string) => !updatedSelection[key]
        );

        if (keysToRemove.length) removeSelectedIds(keysToRemove);
      }

      mapWellSelectNotInGridRef.current = {
        isHeaderClicked: false,
        isMapClicked: false,
      };
    },
    [selectedWellIds, selectedPermitIds, selectedGridDataKeys, allGridData]
  );

  useEffect(() => {
    if (
      selectedWellIdsKeys.length &&
      chartData.length &&
      !isEqual(prevSelectedWellIdsKeys, selectedWellIdsKeys)
    ) {
      handleWellChartUpdate();
    }
  }, [JSON.stringify(selectedWellIdsKeys)]);

  useEffect(() => {
    if (selectedGridDataKeys.length && chartData.length) {
      handleWellPermitChartUpdate();
    }
  }, [JSON.stringify(selectedGridDataKeys)]);

  useEffect(() => {
    // Manually disable select all checkbox when
    // all well grid data is still loading
    if (gridRef?.current) {
      const element: Document = gridRef.current;
      const selectAllCheckbox = element.querySelector(
        '[aria-label="Select all rows"]'
      );

      if (selectAllCheckbox) {
        if (allWellGridDataLoading) {
          selectAllCheckbox.setAttribute("disabled", "true");
        } else {
          selectAllCheckbox.removeAttribute("disabled");
        }
      }
    }

    // manual updating of selected wells when doing grid filter
    if (!allWellGridDataLoading && isGridFilteredRef.current) {
      const newSelectedData = handleNewSelection(allSelectedGridData);

      updateSelectedWellIds(
        newSelectedData.newSelectedWellIdKeys,
        newSelectedData.newSelectedWellData,
        newSelectedData.newSelectedWellIds
      );
      updateSelectedPermitIds(
        newSelectedData.newSelectedPermitIdKeys,
        newSelectedData.newSelectedPermitData,
        newSelectedData.newSelectedPermitIds
      );
    }
  }, [allWellGridDataLoading]);

  useEffect(() => {
    isLoadingSavedSearchFileUpload &&
      setLoading(isLoadingSavedSearchFileUpload);
  }, [isLoadingSavedSearchFileUpload]);

  //To style well grid selection in Map
  useEffect(() => {
    // Has these conditions that prevents this from
    // being called by other components
    if (
      handleGridWellSelectionRef?.current ||
      isGridFilteredRef?.current ||
      mapWellSelectNotInGridRef?.current.isHeaderClicked
    ) {
      const uniqueParentWellIDs: SelectedKeys = {};
      const uniqueBottomWellboreIDs: SelectedKeys = {};
      const uniqueUWIs: SelectedKeys = {};

      for (const key in selectedWellData) {
        const value = selectedWellData[key];
        if (value && value.WellID) {
          const { ParentWellID, BottomWellboreID } = value;
          uniqueParentWellIDs[ParentWellID] = value.WellID;
          uniqueBottomWellboreIDs[BottomWellboreID] = value.WellID;
          //make UWI optional
          if (value.UWI) {
            const UWI = getUWIByProperties(value.UWI);
            uniqueUWIs[UWI] = value.WellID;
          }
        }
      }

      updateSelectedMapParentWellIDs(uniqueParentWellIDs);
      updateSelectedBottomWellboreIDs(uniqueBottomWellboreIDs);
      updateSelectedWellUwis(uniqueUWIs);
      handleGridWellSelectionRef.current = false;
      isGridFilteredRef.current = false;
    }
  }, [selectedWellData]);

  const orderedColumns = useMemo(() => {
    return columnsOrder
      .filter((column) => allColumns.some((item) => item.field === column))
      .map((field) => allColumns.find((column) => column.field === field));
  }, [columnsOrder]) as GridColDef[];

  const handleFilterCall = (newFilterModel: GridFilterModel) => {
    isGridFilteredRef.current = true;
    setLoading(true);
    if (sortByAnalysis) setSortBySelectedDataFromApi(true);
    getMapGridFilterData(newFilterModel);
  };

  const handleOnRowsScrollEnd = useCallback(() => {
    if (hasMore) {
      setLoading(true);
      if (currentRows >= totalFetchedRows) {
        if (
          (gridTotalCount > gridFilteredCount &&
            currentRows >= gridFilteredCount) ||
          (gridTotalCount === gridFilteredCount &&
            currentRows >= gridTotalCount)
        ) {
          setHasMore(false);
          setLoading(false);
        } else {
          if (allWells.length > 0) {
            const newOffset = offset + pageLimit;
            setOffset(newOffset);
            if (sortByAnalysis) setSortBySelectedDataFromApi(true);
            getNextBatchWellData(newOffset);
          }
        }
      } else {
        const { nextSetOfData, nextRowsCount } = getNext500WellData(
          currentRows,
          rowSize,
          totalFetchedRows,
          allWells
        );
        if (setTimeOutRef.current) {
          clearTimeout(setTimeOutRef.current);
          setTimeOutRef.current = null;
        }

        // 0.5 second delay
        setTimeOutRef.current = setTimeout(() => {
          setLoadedWellData(loadedWellData.concat(nextSetOfData));
          setHasMore(true);
          setCurrentRows(nextRowsCount);
          setLoading(false);
        }, 500);
      }
    }
  }, [
    hasMore,
    currentRows,
    totalFetchedRows,
    gridTotalCount,
    gridFilteredCount,
    allWells,
    offset,
    pageLimit,
    getNextBatchWellData,
    getNext500WellData,
    loadedWellData,
  ]);

  const exportAsExcel = () => {
    //get visible columns
    const visibleColumns: VisibleColumns[] = apiRef.current
      .getVisibleColumns()
      .filter((col) => col.field !== "__check__")
      .map((filteredCol) => {
        return { field: filteredCol.field, header: filteredCol.headerName };
      });

    exportDataAsExcelCallback(allWells, visibleColumns);
  };

  const handleSortingCall = (newSortModel: GridSortModel) => {
    if (
      !searchCriteria?.currentBounds?.length &&
      !searchCriteria?.drawnPolygons?.length &&
      !searchCriteria?.searchedUWIs?.length &&
      !searchCriteria?.fileId &&
      !searchCriteria?.shapeId
    )
      return;

    if (sortByAnalysis) setSortBySelectedDataFromApi(true);
    sortGridCallback(newSortModel);
  };

  const handleColumnHeaderClick = (
    e: GridColumnHeaderParams<GridValidRowModel>
  ) => {
    if (e.field === "__check__") {
      if (allWellGridDataLoading) return;
      if (mapWellSelectNotInGridRef.current.isMapClicked)
        mapWellSelectNotInGridRef.current.isHeaderClicked = true;

      updateWellSelectTrigger("grid");
      headerSelectCallback();
    }
  };

  const handleColumnOrderChange = debounce(
    (params: GridColumnOrderChangeParams) => {
      const newColumnsOrder = columnsOrder.filter(
        (field) => params.column.field !== field
      );

      newColumnsOrder.splice(params.targetIndex - 1, 0, params.column.field);
      updateColumnsOrder(newColumnsOrder);
    },
    1000
  );

  const handleFilterReset = () => {
    resetDefaultGridFilter();
    apiRef.current.setFilterModel(defaultFilterModel);
  };

  const handledeterminateCheckbox = useCallback(() => {
    const determinateCheckbox = document.querySelector(
      ".MuiDataGrid-columnHeaderCheckbox input[data-indeterminate='false']"
    );

    //set checkbox to be indeterminate
    if (determinateCheckbox) {
      mapWellSelectNotInGridRef.current.isMapClicked = true;
      mapWellSelectNotInGridRef.current.isHeaderClicked = false;

      setCheckboxHeaderInterdetminate(determinateCheckbox);
    } else {
      if (selectedGridDataKeys.length < gridFilteredCount) {
        mapWellSelectNotInGridRef.current.isMapClicked = true;
        mapWellSelectNotInGridRef.current.isHeaderClicked = false;

        setTimeout(() => {
          const determinateCheckbox = document.querySelector(
            ".MuiDataGrid-columnHeaderCheckbox input[data-indeterminate='false']"
          );
          if (determinateCheckbox) {
            setCheckboxHeaderInterdetminate(determinateCheckbox);
          } else {
            //sometimes indeterminate is true but svg is not updated
            //case: select wells in map that are not yet loaded in grid
            //click "Selected for Analysis", then deselect again
            const indeterminateCheckbox = document.querySelector(
              ".MuiDataGrid-columnHeaderCheckbox input[data-indeterminate='true']"
            );
            const pathIsIndeterminate = document.querySelector(
              ".MuiDataGrid-columnHeaderCheckbox svg path[d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z']"
            );

            if (indeterminateCheckbox && !pathIsIndeterminate) {
              setCheckboxHeaderDeterminate(indeterminateCheckbox);
            }
          }
        }, 100);
      }
    }
  }, [
    selectedGridDataKeys,
    gridFilteredCount,
    setCheckboxHeaderInterdetminate,
  ]);

  const handleIndeterminateCheckbox = useCallback(() => {
    const indeterminateCheckbox = document.querySelector(
      ".MuiDataGrid-columnHeaderCheckbox input[data-indeterminate='true']"
    );
    if (indeterminateCheckbox) {
      mapWellSelectNotInGridRef.current = {
        isHeaderClicked: false,
        isMapClicked: false,
      };
      setCheckboxHeaderDeterminate(indeterminateCheckbox);
    }
  }, [setCheckboxHeaderDeterminate]);

  useEffect(() => {
    if (batchWellGridDataSuccess) {
      updateWellSelectTrigger("");
      if (batchWellGridData && batchWellGridData.length > 0) {
        if (offset === 0) {
          const firstSetofWell = batchWellGridData.slice(0, rowSize);
          convertWellDataArrayToObject(batchWellGridData);
          setAllWells(batchWellGridData);
          setTotalFetchedRows(batchWellGridData.length);

          if (batchWellGridData.length > rowSize) {
            setHasMore(true);
            setCurrentRows(rowSize);
          } else {
            setHasMore(false);
            setCurrentRows(batchWellGridData.length);
          }

          setLoadedWellData(firstSetofWell);
          if (!sortByAnalysis) setSortBySelectedDataFromApi(false);
        }

        if (offset > 0) {
          const next500rows = currentRows + rowSize;
          const concatGridData = allWells.concat(batchWellGridData);
          const nextSetofWells = batchWellGridData.slice(0, rowSize);
          convertWellDataArrayToObject(concatGridData);
          setAllWells(concatGridData);
          setTotalFetchedRows(concatGridData.length);

          if (next500rows < concatGridData.length) {
            setHasMore(true);
            setCurrentRows(next500rows);
          } else {
            setHasMore(false);
            setCurrentRows(concatGridData.length);
          }

          setLoadedWellData(loadedWellData.concat(nextSetofWells));
          if (!sortByAnalysis) setSortBySelectedDataFromApi(false);
        }

        if (!sortByAnalysis && selectedGridDataKeys.length) {
          handledeterminateCheckbox();
        }
      } else {
        setHasMore(false);
        setCurrentRows(0);
        setAllWells([]);
        setLoadedWellData([]);
        setTotalFetchedRows(0);

        setMessage("No result/s to display.");
      }
      setLoading(false);
    } else {
      if (gridTotalCount === 0 && !isLoadingSavedSearchFileUpload) {
        setMessage("No result/s to display.");
        setAllWells([]);
        setLoadedWellData([]);
        setTotalFetchedRows(0);
        setCurrentRows(0);
        setLoading(false);
      }
    }
  }, [batchWellGridDataSuccess, batchWellGridData, gridTotalCount]);

  useEffect(() => {
    if (gridDataError) {
      setLoading(false);
      setMessage("Something Went Wrong");
    }
  }, [gridDataError]);

  useEffect(() => {
    if (batchWellGridDataLoading) {
      setLoading(true);

      if (RESET_GRID_TRIGGERS.includes(gridWellDataTrigger)) {
        //reset scroll back on top of the grid
        if (apiRef?.current?.scroll) {
          apiRef.current.scroll({ top: 0 });
        }
        setOffset(0);
      }
    }
  }, [batchWellGridDataLoading]);

  useEffect(() => {
    if (selectedGridDataKeys.length) {
      handledeterminateCheckbox();
    } else {
      handleIndeterminateCheckbox();
    }
  }, [selectedGridDataKeys]);

  useEffect(() => {
    if (!allWells.length) return;

    if (sortByAnalysis) {
      if (isSelectedAllGrid || isSelectedWellsInGrid(selectedGridDataKeys)) {
        const sortedData = sortGridBySelected(allWells, sortModel);
        const currentPageSizeData = sortedData.slice(0, currentRows);

        setLoadedWellData(currentPageSizeData);
      } else {
        setSortBySelectedDataFromApi(true);
        getGridDataForSortBySelected();
      }
    } else {
      if (sortBySelectedDataFromApi) {
        setSortBySelectedDataFromApi(false);
        getGridDataForSortBySelected();
      } else {
        const currentPageSizeData = allWells.slice(0, currentRows);
        setLoadedWellData(currentPageSizeData);
      }
    }
  }, [sortByAnalysis, sortModel]);

  useEffect(() => {
    if (!allWells.length || !sortByAnalysis) return;

    if (wellSelectTrigger === WELL_SELECT_GRID_TRIGGER) {
      if (selectedGridDataKeys.length) {
        //call the api to avoid data confusion in datagrid
        if (selectedGridDataKeys.length > allWells.length) {
          setSortBySelectedDataFromApi(true);
          getGridDataForSortBySelected();
        } else {
          if (sortBySelectedDataFromApi) {
            if (isSelectedWellsInGrid(selectedGridDataKeys)) {
              //pass non sorted by selected data to preserve grid sort order
              const sortedData = sortGridBySelected(allWells, sortModel);
              const currentPageSizeData = sortedData.slice(0, currentRows);
              setLoadedWellData(currentPageSizeData);
            } else {
              setSortBySelectedDataFromApi(true);
              getGridDataForSortBySelected();
            }
          } else {
            //pass non sorted by selected data to preserve grid sort order
            const sortedData = sortGridBySelected(allWells, sortModel);
            const currentPageSizeData = sortedData.slice(0, currentRows);

            setLoadedWellData(currentPageSizeData);
          }
        }
      } else {
        updateSortByAnalysis(false); //disable sort by selected if all wells are deselected
      }
    } else if (
      wellSelectTrigger === WELL_SELECT_DRAW_TRIGGER ||
      wellSelectTrigger === WELL_SELECT_MAP_TRIGGER
    ) {
      if (isSelectedWellsInGrid(selectedGridDataKeys)) {
        //pass non sorted by selected data to preserve grid sort order
        const sortedData = sortGridBySelected(allWells, sortModel);
        const currentPageSizeData = sortedData.slice(0, currentRows);
        setLoadedWellData(currentPageSizeData);
      } else {
        setSortBySelectedDataFromApi(true);
        getGridDataForSortBySelected();
      }
    }
  }, [selectedGridDataKeys, allWells, sortModel]);

  return (
    <div className={getGridContainerClassName(isFullScreenGrid)}>
      <div
        className={getGridClassName(isFullScreenGrid, width)}
        ref={gridContainerRef}
      >
        {showGridHeader && (
          <GridPlotHeader
            exportAsExcel={exportAsExcel}
            resetFilters={handleFilterReset}
          />
        )}

        <Box className={getGridSubContainerClassName()}>
          <Box
            className={getHeaderClassName(
              isOpenAppLevelNav,
              isOpenLeftSidePanel
            )}
          >
            <DataGridPremium
              ref={gridRef}
              className={classNames("tgs-data-grid", {
                "data-loading": allWellGridDataLoading,
              })}
              columnHeaderHeight={38}
              columns={[wellPanelColumn, ...orderedColumns]}
              getRowId={(row) => row.WellHeaderPermitKey}
              rows={loadedWellData}
              rowSelectionModel={selectedGridDataKeys}
              apiRef={apiRef}
              onRowSelectionModelChange={handleGridWellSelection}
              onRowsScrollEnd={handleOnRowsScrollEnd}
              rowHeight={26}
              loading={loading}
              sortModel={sortModel}
              sortingMode="server"
              onSortModelChange={(newSortModel) => {
                handleSortingCall(newSortModel);
              }}
              onColumnOrderChange={handleColumnOrderChange}
              columnVisibilityModel={columnVisibilityModel}
              onColumnHeaderClick={handleColumnHeaderClick}
              slots={{
                loadingOverlay: LinearProgress,
                footer: Footer,
                columnMenu: CustomColumnMenu,
                filterPanel: () => <></>,
                columnHeaderFilterIconButton: HeaderFilterIconButton,
                noRowsOverlay: NoResultDisplay,
                noResultsOverlay: NoResultDisplay,
              }}
              slotProps={{
                footer: { gridFilteredCount, currentRows },
                noRowsOverlay: { message },
                noResultsOverlay: { message },
              }}
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                handleFilterCall(newFilterModel);
              }}
              keepNonExistentRowsSelected
              disableColumnSelector
              checkboxSelection
            />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default DataGrid;

import { useCallback } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { LAYER_NAME_TO_LABEL_MAPPER } from "../../constants/constants";
import {
  CARBON_LAYER_BASINS,
  CARBON_STORAGE_FORMATIONS_BY_BASINS,
  CARBON_STORAGE_LABEL,
  ORDERED_CARBON_LAYERS,
} from "../../constants/map/carbonStorage";

import useMapStore from "../../store/map/mapStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";

import AccordionSection from "../common/AccordionSection";

interface CarbonStorageAccordion {
  layerLegendCallback: (layerName: string, toAdd: boolean) => void;
}

const CarbonStorageAccordion = ({
  layerLegendCallback,
}: CarbonStorageAccordion) => {
  const layers = useMapStore((state) => state.layers);
  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const carbonStorageInfo = useMapSettingsStore(
    (state) => state.carbonStorageInfo
  );
  const toggleCarbonStorageSelected = useMapSettingsStore(
    (state) => state.toggleCarbonStorageSelected
  );
  const updateCarbonStorageBasinStatus = useMapSettingsStore(
    (state) => state.updateCarbonStorageBasinStatus
  );
  const updateCarbonStorageBasinFormation = useMapSettingsStore(
    (state) => state.updateCarbonStorageBasinFormation
  );

  const getFormationLayers = useCallback(
    (basinName: string) =>
      layers.filter((layer) =>
        CARBON_STORAGE_FORMATIONS_BY_BASINS[basinName].includes(layer.name)
      ),
    [layers]
  );

  const handleToggleLayer = (
    layerName: string,
    boolOverride?: boolean,
    isFormationChange?: boolean
  ) => {
    if (!isFormationChange)
      layerLegendCallback(CARBON_STORAGE_LABEL, !!boolOverride);
    toggleLayer(layerName, boolOverride);
  };

  const handleChange = (newFormationName: string, basinName: string) => {
    if (
      carbonStorageInfo.isSelected &&
      carbonStorageInfo.basins[basinName].isSelected
    ) {
      // remove previous formation and add new formation
      handleToggleLayer(
        carbonStorageInfo.basins[basinName].formationName,
        false,
        true
      );
      handleToggleLayer(newFormationName, true);
    }
    updateCarbonStorageBasinFormation(basinName, newFormationName);
  };

  return (
    <AccordionSection
      title={CARBON_STORAGE_LABEL}
      className="carbon-layers-accordion"
      hasCheckbox
      hasTooltip
      expandIconEnd
      isSelected={carbonStorageInfo.isSelected}
      onSelect={() => {
        const { isSelected } = carbonStorageInfo;
        const newState = !isSelected;
        toggleCarbonStorageSelected(newState);

        // temporary logic to auto select/deselect basin
        // until other basins are added as per requirement
        updateCarbonStorageBasinStatus(
          CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY,
          newState
        );
        handleToggleLayer(
          carbonStorageInfo.basins[
            CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY
          ].formationName,
          newState
        );
      }}
    >
      {ORDERED_CARBON_LAYERS.map((basinName) => (
        <AccordionSection
          key={basinName}
          title={basinName}
          className="nested-sub-accordion"
          hasCheckbox
          hasTooltip
          expandIconEnd
          isSelected={carbonStorageInfo.basins[basinName].isSelected}
          onSelect={() => {
            const newState = !carbonStorageInfo.basins[basinName].isSelected;
            if (newState && !carbonStorageInfo.isSelected)
              toggleCarbonStorageSelected(true);

            handleToggleLayer(
              carbonStorageInfo.basins[basinName].formationName,
              newState
            );
            updateCarbonStorageBasinStatus(basinName, newState);
          }}
        >
          <FormControl className="custom-select">
            <InputLabel id="formation-name-select">Formation Name</InputLabel>
            <Select
              labelId="formation-name-select"
              label="Formation Name"
              defaultValue={CARBON_STORAGE_FORMATIONS_BY_BASINS[basinName][0]}
              value={carbonStorageInfo.basins[basinName].formationName}
              onChange={(e) => handleChange(e.target.value, basinName)}
            >
              {getFormationLayers(basinName).map((layer) => (
                <MenuItem key={layer.name} value={layer.name}>
                  {LAYER_NAME_TO_LABEL_MAPPER[layer.name]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AccordionSection>
      ))}
    </AccordionSection>
  );
};

export default CarbonStorageAccordion;

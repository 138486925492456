import { useDebouncedCallback } from "use-debounce";

import { REGEXP_UWI_DELIMITERS } from "../../../constants/regexp";
import { PASTED_UWI_LIMIT } from "../../../constants/validation";

import useExportCardStore from "../../../store/exportCard/exportCardStore";
import useQueryBuilderStore from "../../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";

import { hasInvalidUwi } from "../../../utils/search/validation";

import useUwiFileUpload from "../useUwiFileUpload";

const useUwiChangeHandler = () => {
  const updateIsSearchCriteriaChange = useExportCardStore(
    (state) => state.updateIsSearchCriteriaChange
  );
  const updateExportUwiList = useExportCardStore(
    (state) => state.updateExportUwiList
  );
  const updateExportWellCounts = useExportCardStore(
    (state) => state.updateExportWellCounts
  );

  const setIsLoadingUWIFileUpload = useUWISearchStore(
    (state) => state.setIsLoadingUWIFileUpload
  );

  const fileTimeStamp = useUWISearchStore((state) => state.fileTimeStamp);
  const updateFileTimeStamp = useUWISearchStore(
    (state) => state.updateFileTimeStamp
  );

  const updateSearchUWIText = useUWISearchStore(
    (state) => state.updateSearchUWIText
  );

  const setIsValidatingUWITexts = useUWISearchStore(
    (state) => state.setIsValidatingUWITexts
  );

  const updateUploadedUWIFile = useUWISearchStore(
    (state) => state.updateUploadedUWIFile
  );
  const updateUploadedUwiFileId = useUWISearchStore(
    (state) => state.updateUploadedUwiFileId
  );

  const updateUWIsToSearch = useUWISearchStore(
    (state) => state.updateUWIsToSearch
  );
  const isInvalidUWIText = useUWISearchStore((state) => state.isInvalidUWIText);
  const updateIsInvalidUWIText = useUWISearchStore(
    (state) => state.updateIsInvalidUWIText
  );
  const setIsValidUwiFile = useUWISearchStore(
    (state) => state.setIsValidUwiFile
  );
  const updateIsAutoConvertedFileFromText = useUWISearchStore(
    (state) => state.updateIsAutoConvertedFileFromText
  );
  const resetUWIUploadStates = useUWISearchStore(
    (state) => state.resetUWIUploadStates
  );

  // UWI Attrib Check for Export
  const updateIsQBUpdated = useQueryBuilderStore(
    (state) => state.updateIsQBUpdated
  );

  const { getPresignedUrl } = useUwiFileUpload();

  // const [uwiTextOnBlur, setUwiTextOnBlur] = useState("");

  const getUWIListFromText = (uwiText: string) => {
    return uwiText.trim().split(REGEXP_UWI_DELIMITERS).filter(Boolean);
  };

  const validateAndSanitizeUWIs = (
    uwiText: string,
    shouldUpdateText?: boolean
  ) => {
    updateExportUwiList([]);
    const uwiList = getUWIListFromText(uwiText);
    const sanitizedText = uwiList.join("\n");
    if (shouldUpdateText) updateSearchUWIText(sanitizedText);

    const isInvalid = hasInvalidUwi(sanitizedText, uwiList.length);
    updateIsInvalidUWIText(isInvalid);
    updateIsQBUpdated(!isInvalid);
    updateIsSearchCriteriaChange(!isInvalid);
    updateExportWellCounts(!isInvalid);
    return { uwiList: isInvalid ? null : uwiList, isInvalid };
  };

  const validateAndSanitizeWithDebounce = useDebouncedCallback(
    (uwiText: string) => {
      const { isInvalid } = validateAndSanitizeUWIs(uwiText, true);
      if (isInvalid) return;

      // Comment these function calls when processing UWIs on blur
      resetUWIUploadStates();
      updateUWIStates(uwiText);
    },
    1000
  );

  const updateUWIStates = (
    uwiText: string,
    uploadedUWIFile: File | null = null
  ) => {
    const uwiList = getUWIListFromText(uwiText);

    const shouldAutoConvertToFile = uwiList.length > PASTED_UWI_LIMIT;
    let file = uploadedUWIFile;

    if (!uploadedUWIFile && shouldAutoConvertToFile) {
      let timeStamp = fileTimeStamp;
      if (!timeStamp) {
        timeStamp = Date.now();
        updateFileTimeStamp(timeStamp);
      }
      file = new File([uwiText], `uwi-file-${timeStamp}.txt`, {
        type: "text/plain",
      });
      updateIsAutoConvertedFileFromText(true);
      updateUploadedUWIFile(file);
      setIsValidUwiFile(true);
    } else {
      updateIsAutoConvertedFileFromText(false);
    }

    if (file) {
      getPresignedUrl(file);
      updateUWIsToSearch([]);
    } else {
      updateUWIsToSearch(uwiList);
    }
    setIsValidatingUWITexts(false);
  };

  // Saving for Reference for processing UWIs on blur
  // const handleUwiTextOnBlur = useDebouncedCallback((uwiText: string) => {
  //   if (isInvalidUWIText || uwiText === uwiTextOnBlur) return;
  //   setUwiTextOnBlur(uwiText);
  //   resetUWIUploadStates();
  //   updateUWIStates(uwiText);
  // }, 1000);

  const handleUwiTextOnChange = (uwiText: string) => {
    updateSearchUWIText(uwiText);
    if (!uwiText) {
      updateIsInvalidUWIText(false);
      updateUWIsToSearch([]);
      updateUploadedUWIFile(null);
      updateUploadedUwiFileId(null);
      return;
    }

    setIsValidatingUWITexts(true);
    validateAndSanitizeWithDebounce(uwiText);
  };

  const handleUwiFileOnChange = (uwiText: string, file: File) => {
    const { uwiList } = validateAndSanitizeUWIs(uwiText, false);
    if (uwiList === null) {
      setIsValidUwiFile(false);
      setIsLoadingUWIFileUpload(false);
      return;
    }
    setIsValidUwiFile(true);
    updateUWIStates(uwiText, file);
  };

  return {
    handleUwiTextOnChange,
    handleUwiFileOnChange,
  };
};

export default useUwiChangeHandler;

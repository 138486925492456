import { useState } from "react";

import axios, { AxiosError } from "axios";

import { APIErrorResponse } from "../../../types/common/api";
import { Bounds } from "../../../types/common/attributes";
import { GetSavedSearch } from "../../../types/panels/savedSearchPanel/hooks";
import { SavedSearchDataResponse } from "../../../types/panels/savedSearchPanel/savedSearchData";

import config from "../../../configs/appSettings";

import { ATTRIBUTES_TAB, UWI_TAB } from "../../../constants/constants";
import { SIDEBAR_MENU_TEXTS } from "../../../constants/panels/menu";
import { SAVED_SEARCH_VISIBILITY_PRIVATE } from "../../../constants/panels/savedSearchPanel/hooks";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";
import { SEARCH_TYPES } from "../../../constants/panels/searchPanel/search";

import useDataGridStore from "../../../store/grid/dataGridStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import useStore from "../../../store/useStore";

import {
  createRecordTypeBounds,
  getRecordTypeOnBound,
} from "../../../utils/common/boundsData";
import { formatToNonAutoSearchType } from "../../../utils/savedSearch/savedData";

import useSavedSearchHelper from "../useSavedSearchHelper";
import useLoadConfigs from "./useLoadConfigs";
import useLoadSearches from "./useLoadSearches";

const useGetSavedSearch = () => {
  const authUser = useStore((state) => state.authUser);
  const updateLastSearchedBy = useStore((state) => state.updateLastSearchedBy);
  const updateSelectedSavedSearchData = useStore(
    (state) => state.updateSelectedSavedSearchData
  );

  const updateActiveSearchPanelTab = usePanelsStore(
    (state) => state.updateActiveSearchPanelTab
  );
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);

  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const toggleGridHeader = useDataGridStore((state) => state.toggleGridHeader);
  const updateInitialGridSearchMade = useDataGridStore(
    (state) => state.updateInitialGridSearchMade
  );
  const updateInitialWellDataFetched = useDataGridStore(
    (state) => state.updateInitialWellDataFetched
  );
  const updateNewSearchCriteria = useDataGridStore(
    (state) => state.updateNewSearchCriteria
  );
  const updateSortPayload = useDataGridStore(
    (state) => state.updateSortPayload
  );

  const {
    loadGridConfigs,
    loadModulesConfigs,
    loadMapConfigs,
    loadDashboardChartConfigs,
    loadUnitOfMeasure,
  } = useLoadConfigs();
  const { loadAttributeSearch, loadUWISearch, loadUWIFileUpload } =
    useLoadSearches();
  const { error, setError, catchError } = useSavedSearchHelper();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SavedSearchDataResponse | null>(null);

  const updateLastRunDate: GetSavedSearch = (id, searchName, searchData) => {
    const body = {
      userId: `${authUser.uid}`,
      searchName,
      searchData,
      lastRunTime: "true",
    };

    axios
      .post(`${config.endpoints.wellService}api/searches/update/${id}`, body)
      .catch((error) => catchError(error))
      .finally(() => setIsLoading(false));
  };

  const getSavedSearch = async (id: string) => {
    try {
      setIsLoading(true);
      setData(null);
      setError(null);

      const { data } = await axios.get<SavedSearchDataResponse>(
        `${config.endpoints.wellService}api/searches/${id}`
      );
      const { id: searchId, searchName, searchData, searchType } = data;
      const nonAutoSearchType = formatToNonAutoSearchType(searchType);
      if (data.visibility === SAVED_SEARCH_VISIBILITY_PRIVATE)
        updateLastRunDate(searchId, searchName, searchData);
      updateActiveSearchPanelTab(
        nonAutoSearchType === SEARCH_TYPES.ATTRIBUTE_SEARCH
          ? ATTRIBUTES_TAB
          : UWI_TAB
      );
      updateInitialWellDataFetched(true);

      loadUnitOfMeasure(searchData.unitOfMeasureConfiguration);
      const gridConfigs = loadGridConfigs(searchData.gridConfiguration);

      let searchState = {};
      if (nonAutoSearchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) {
        searchState = loadAttributeSearch(
          searchData.attributeSearch,
          searchData.sortPerAttribute
        );
      } else {
        //add default record type bound for uwi search
        const recordType = createRecordTypeBounds(
          RECORD_TYPES.WELLS_AND_PERMIT
        );
        searchState = { ...searchState, ...{ currentBounds: [recordType] } };
        if (nonAutoSearchType === SEARCH_TYPES.UWI_SEARCH) {
          const { loadedUWIs } = loadUWISearch(searchData.uwiSearch);
          searchState = { ...searchState, ...loadedUWIs };
        } else if (nonAutoSearchType === SEARCH_TYPES.UWI_FILE_SEARCH) {
          const uwiUploadState = await loadUWIFileUpload(searchData.fileUpload);
          searchState = { ...searchState, ...uwiUploadState };
        } else {
          const uwiConvertedFileState = await loadUWIFileUpload(
            searchData.fileUpload,
            searchData.uwiSearch
          );
          searchState = { ...searchState, ...uwiConvertedFileState };
        }
      }

      updateInitialGridSearchMade(true);
      toggleGrid(true);
      toggleGridHeader(true);
      updateLastSearchedBy(nonAutoSearchType);
      updateNewSearchCriteria({
        drawnPolygons: [],
        polygonType: "",
        currentBounds: [],
        searchedUWIs: [],
        shapeId: "",
        fileId: "",
        ...gridConfigs,
        ...searchState,
      });

      if (gridConfigs.sort?.length) {
        updateSortPayload(gridConfigs.sort);
      } else {
        updateSortPayload([]);
      }

      loadMapConfigs(searchData.mapConfiguration);
      loadModulesConfigs(searchData.modulesConfiguration);
      loadDashboardChartConfigs(searchData.dashboardChartConfiguration);

      if (searchState && "currentBounds" in searchState) {
        const recordType = getRecordTypeOnBound(
          searchState.currentBounds as Bounds
        );
        switch (recordType) {
          case RECORD_TYPES.WELL:
          case RECORD_TYPES.WELLS_AND_PERMIT:
            updateActivePanel(SIDEBAR_MENU_TEXTS.DEFAULT_CHARTS);
            break;
          case RECORD_TYPES.PERMIT:
            updateActivePanel(SIDEBAR_MENU_TEXTS.SEARCH);
            break;
          default:
            updateActivePanel(SIDEBAR_MENU_TEXTS.SEARCH);
        }
      }

      setData(data);
      updateSelectedSavedSearchData(data);
    } catch (error) {
      catchError(error as AxiosError<APIErrorResponse>);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    updateLastRunDate,
    getSavedSearch,
  };
};

export default useGetSavedSearch;
